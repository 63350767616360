import { AfterViewInit, Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { CustomDesignItemType } from '@iupics-components/models/custom-design';
import SpecificWindowUiComponent from '@iupics-components/specific/window/specific-window-ui/specific-window-ui.component';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { DynamicComponent } from '@iupics-manager/models/dynamic-component';
import { Global } from '@iupics-manager/models/global-var';
import { IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { InfoDialogType } from './../info-dialog/info-dialog.component';
import { ProgressBarModule } from 'primeng/progressbar';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SidebarModule } from 'primeng/sidebar';
import { NgClass, NgIf } from '@angular/common';
import { ApizGridHeadlessComponent } from '@iupics/apiz-grid';
import ModalUiComponent from '@iupics-components/standard/layouts/modal-ui/modal-ui.component';
import JoinFileUiComponent from '@iupics-components/specific/window/join-file-ui/join-file-ui.component';
import ButtonUiComponent from '@iupics-components/standard/fields/button-ui/button-ui.component';
import { SafeHtmlPipe } from '@iupics-components/pipes/safeHtml/safeHtml.pipe';

@Component({
  selector: 'iu-suppression-fonction-erronee',
  templateUrl: './suppression-fonction-erronee.component.html',
  styleUrls: ['./suppression-fonction-erronee.component.scss'],
  standalone:true,
  imports: [
    ProgressBarModule,
    ScrollPanelModule,
    SidebarModule,
    NgIf,
    TranslateModule,
    ApizGridHeadlessComponent,
    NgClass,
    ModalUiComponent,
    JoinFileUiComponent,
    ButtonUiComponent,
    SafeHtmlPipe,
  ],
})
export class SuppressionFonctionErroneeComponent extends SpecificWindowUiComponent implements OnInit, AfterViewInit {
  @ViewChild('filterNumber', { read: ViewContainerRef, static: true })
  vcrFilterNumber: ViewContainerRef;
  @ViewChild('filterDate', { read: ViewContainerRef, static: true })
  vcrFilterDate: ViewContainerRef;
  @ViewChild('table', { read: ViewContainerRef, static: true })
  vcrTable: ViewContainerRef;
  @ViewChild('actions', { read: ViewContainerRef, static: true })
  vcrActions: ViewContainerRef;

  ngOnInit() {
    this.uiCreator.getSpecificWindow(this.formId).subscribe((specificWindow) => {
      this.customDesignArray.push(
        {
          vcr: 'vcrFilterNumber',
          type: CustomDesignItemType.FIELD,
          columnName: 'NbJours',
          cssClass: 'p-col-6'
        },
        {
          vcr: 'vcrFilterDate',
          type: CustomDesignItemType.FIELD,
          columnName: 'StartDate',
          cssClass: 'p-col-6'
        },
        {
          vcr: 'vcrFilterDate',
          type: CustomDesignItemType.FIELD,
          columnName: 'EndDate',
          cssClass: 'p-col-6'
        },
        {
          vcr: 'vcrTable',
          type: CustomDesignItemType.GRID,
          tableName: 'Suppression fonction erronée Grid',
          cssClass: 'p-col-12'
        },
        {
          vcr: 'vcrActions',
          type: CustomDesignItemType.FIELD,
          columnName: 'Delete',
          cssClass: 'p-col-6'
        }
      );
    });
    const item: DynamicComponent = {
      container: this,
      DOMParentComponent: this,
      component: 'SpecificWindowUiComponent',
      cssClass: 'p-col-12',
      isCssOnComponent: false,
      tabId: this.formId,
      gridPaginator: false
    };
    this.windowFactory.newEventHandler({
      type: IupicsTypeEvent.showSpecificWindow,
      item: item
    });
    this.dataStore.data['NbJours'] = {
      id: this.translateService.instant('suppFonctionErr.default_number_fct_err'),
      displayValue: this.translateService.instant('suppFonctionErr.default_number_fct_err')
    };
  }

  ngAfterViewInit() {
    this.refresh();
  }

  refresh() {
    const dateStart = new Date().setDate(new Date().getDate() - parseInt(this.dataStore.data['NbJours'].id));
    this.setDatacontainerValue('StartDate', moment(dateStart).format('YYYY-MM-DDTHH:mm:ss.SSS'));
    this.setDatacontainerValue('EndDate', moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSS'));
    this.refreshGrids(this.dataStore, false);
  }
  notifyFromDataChange(item?: any) {
    if (item.data['columnName'] === 'NbJours' && this.dataStore && this.dataStore.data['NbJours']) {
      this.clearAllGridsSelection();
      this.refresh();
    }
  }

  processDelete(item) {
    Global.infoDialog.message = {
      summary: this.translateService.instant('infodialog.dialogs.checkBefore.close.title'),
      detail: this.translateService.instant('infodialog.dialogs.checkBefore.close.detail')
    };

    Global.infoDialog.dialogType = InfoDialogType.CONFIRM_YESNO;
    Global.infoDialog.showInfoDialog();
    const confirm = Global.infoDialog.confirm.subscribe((e: any) => {
      const lengthOfRowSelected = this.gridViews[0].GridTabInfinityScrollUiComponent.grid.api.getSelectedRows().length;
      if (lengthOfRowSelected < 1) {
        this.messageManager.newMessage(
          new IupicsMessage(this.translateService.instant('generic.warning'), 'No rows selected', 'error')
        );
      } else {
        this.executeProcess(item.processId);
      }
      confirm.unsubscribe();
      cancel.unsubscribe();
    });
    const cancel = Global.infoDialog.cancel.subscribe((e) => {
      // ANNULATION
      confirm.unsubscribe();
      cancel.unsubscribe();
    });
  }
  onEndProcess() {
    this.clearAllGridsSelection();
    this.setDatacontainerValue('NbJours', {
      id: this.translateService.instant('suppFonctionErr.default_number_fct_err'),
      displayValue: this.translateService.instant('suppFonctionErr.default_number_fct_err')
    });
    this.refresh();
  }
}
