<div class="iu-specific-window gps-container" [ngClass]="{ 'iu-specific-window-container': !isModal }">
  <p-progressBar
    *ngIf="!isModal && isLoading"
    [mode]="'indeterminate'"
    [value]="0"
    [showValue]="false"
    [styleClass]="'loading-progressbar'"></p-progressBar>
  <p-scrollPanel [style]="{ width: '100%' }" [ngClass]="{ 'iu-modal': isModal }">
    <div class="p-col-12">
      <div class="p-col-12">
        <p class="specific-title">
          <b>{{ 'gpsUnit.title' | translate }}</b>
        </p>
      </div>

      <div class="p-col-12 filters-distance-desktop">
        <ng-template #filtersDistance class="specific-input"></ng-template>
      </div>

      <div class="p-col-12 p-md-6 p-lg-3">
        <ng-template #filters></ng-template>
      </div>

      <div class="p-col-12">
        <p class="specific-title" style="font-size: 18px">
          <b>{{ 'gpsUnit.titleTableUnit' | translate }}</b>
        </p>
        <ng-template #tableParent></ng-template>
      </div>

      <div class="p-col-12">
        <p class="specific-title" style="font-size: 18px">
          <b>{{ 'gpsUnit.titleTableSection' | translate }}</b>
        </p>
        <div class="p-col-12 margin-bottom gps-unit-fitlers-2">
          <ng-template #filters2></ng-template>
        </div>
        <ng-template #tableChild></ng-template>
      </div>

      <div class="p-col-12 p-md-6 p-lg-3 p-mt-5">
        <ng-template #actions></ng-template>
      </div>
    </div>
  </p-scrollPanel>
</div>
