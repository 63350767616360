import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { DataStoreKey } from '@compiere-ws/models/compiere-data-json';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { DocServerService } from '@compiere-ws/services/doc-server/doc-server.service';
import { PoService } from '@compiere-ws/services/po/po.service';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { CustomDesignItemType } from '@iupics-components/models/custom-design';
import GridViewUiComponent from '@iupics-components/standard/grid/grid-view-ui/grid-view-ui.component';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { DynamicComponent } from '@iupics-manager/models/dynamic-component';
import { IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import SpecificWindowUiComponent from '../specific-window-ui/specific-window-ui.component';
import { ProgressBarModule } from 'primeng/progressbar';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SidebarModule } from 'primeng/sidebar';
import { NgClass, NgIf } from '@angular/common';
import { ApizGridHeadlessComponent } from '@iupics/apiz-grid';
import ModalUiComponent from '@iupics-components/standard/layouts/modal-ui/modal-ui.component';
import JoinFileUiComponent from '@iupics-components/specific/window/join-file-ui/join-file-ui.component';
import ButtonUiComponent from '@iupics-components/standard/fields/button-ui/button-ui.component';

@Component({
  selector: 'iu-simple-file-upload',
  templateUrl: './simple-file-upload.component.html',
  styleUrls: ['./simple-file-upload.component.scss'],
  standalone:true,
  imports: [
    ProgressBarModule,
    ScrollPanelModule,
    SidebarModule,
    NgIf,
    TranslateModule,
    ApizGridHeadlessComponent,
    NgClass,
    ModalUiComponent,
    JoinFileUiComponent,
    ButtonUiComponent,
    JoinFileUiComponent,
  ],
})
export class SimpleFileUploadComponent extends SpecificWindowUiComponent implements OnInit {
  @ViewChild('aboveGrid', { read: ViewContainerRef, static: true })
  vcrAboveGrid: ViewContainerRef;
  @ViewChild('underGrid', { read: ViewContainerRef, static: true })
  vcrUnderGrid: ViewContainerRef;
  tableID = -1;
  dsKey: DataStoreKey;
  query: any;
  constructor(
    private docserverService: DocServerService
  ) {
    super();
  }
  initAD_Data() {
    if (this.sourceComponent && this.sourceComponent.dataStored && this.sourceComponent.parentTab) {
      this.tableID = this.sourceComponent.parentTab.data.AD_Table_ID;
      this.dsKey = this.sourceComponent.dataStored.key;
    }
    if (this.tableID < 0 || !this.dsKey) {
      throw new Error(this.translateService.instant('specificWindow.simpleFileUpload.cantJoinFile'));
    }
  }
  ngOnInit(): void {
    this.initAD_Data();
    this.createDocQueryInfo();
    this.customDesignArray.push({
      vcr: 'vcr',
      type: CustomDesignItemType.FIELD,
      columnName: 'FilesToLoad',
      cssClass: 'p-lg-12 p-md-12 p-col-12'
    });

    const item: DynamicComponent = {
      container: this,
      DOMParentComponent: this,
      component: 'SpecificWindowUiComponent',
      cssClass: 'p-col-12',
      isCssOnComponent: false,
      tabId: this.formId,
      gridPaginator: false
    };
    this.windowFactory.newEventHandler({
      type: IupicsTypeEvent.showSpecificWindow,
      item: item
    });
  }
  createDocQueryInfo() {
    if (
      this.sourceComponent &&
      this.sourceComponent.dataStored &&
      this.sourceComponent.dataStored.key &&
      this.sourceComponent.parentTab &&
      this.sourceComponent.parentTab.data &&
      this.sourceComponent.parentTab.data.ADTableID
    ) {
      const recordId = this.sourceComponent.dataStored.key.recordId.split(',');
      const filterFromKey = this.docserverService.createfilterFromKey(this.sourceComponent.dataStored.key);
      this.query = [{
        ...filterFromKey,
        'META|table_id': this.sourceComponent.parentTab.data.ADTableID,
        'META|record_id': recordId.length > 1 ? parseInt(recordId[1], 10) : -1,
        'META|column_name': this.sourceComponent.data.columnName,
        'META|AD_Org_ID': this.sourceComponent.dataStored.data["AD_Org_ID"]?this.sourceComponent.dataStored.data["AD_Org_ID"].id:-1
      }];
    }
  }
  updateNBuploadedFile(){
    if(this.sourceComponent&&this.sourceComponent.updateNbFileAttached){
      this.sourceComponent.updateNbFileAttached();
    }
  }
}
