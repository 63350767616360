import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { PoService } from '@compiere-ws/services/po/po.service';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { CustomDesignItemType } from '@iupics-components/models/custom-design';
import GridViewUiComponent from '@iupics-components/standard/grid/grid-view-ui/grid-view-ui.component';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { DynamicComponent } from '@iupics-manager/models/dynamic-component';
import { IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import SpecificWindowUiComponent from '../specific-window-ui/specific-window-ui.component';
import { ProgressBarModule } from 'primeng/progressbar';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SidebarModule } from 'primeng/sidebar';
import { NgClass, NgIf } from '@angular/common';
import { ApizGridHeadlessComponent } from '@iupics/apiz-grid';
import ModalUiComponent from '@iupics-components/standard/layouts/modal-ui/modal-ui.component';
import JoinFileUiComponent from '@iupics-components/specific/window/join-file-ui/join-file-ui.component';
import ButtonUiComponent from '@iupics-components/standard/fields/button-ui/button-ui.component';
import { SafeHtmlPipe } from '@iupics-components/pipes/safeHtml/safeHtml.pipe';

@Component({
  selector: 'iu-subscription-management',
  templateUrl: './subscription-management.component.html',
  styleUrls: ['./subscription-management.component.scss'],
  standalone:true,
  imports: [
    ProgressBarModule,
    ScrollPanelModule,
    SidebarModule,
    NgIf,
    TranslateModule,
    ApizGridHeadlessComponent,
    NgClass,
    ModalUiComponent,
    JoinFileUiComponent,
    ButtonUiComponent,
    SafeHtmlPipe,
  ],
})
export class SubscriptionManagementComponent extends SpecificWindowUiComponent implements OnInit {
  @ViewChild('aboveGrid', { read: ViewContainerRef, static: true })
  vcrAboveGrid: ViewContainerRef;
  @ViewChild('underGrid', { read: ViewContainerRef, static: true })
  vcrUnderGrid: ViewContainerRef;
  @ViewChild("tableSouscriptions", { read: ViewContainerRef, static: true })
  vcrTableSouscriptions: ViewContainerRef;
  private callMap = new Map<string, Subscription>();
  constructor(
    private poService: PoService
  ) {
    super();
  }

  ngOnInit(): void {
    this.uiCreator.getSpecificWindow(this.formId).subscribe((specificWindow) => {
      const itemFound = specificWindow.items.find((item) => item.component === 'GridViewUiComponent');
      if (itemFound) {
        itemFound.data.hasCheckbox = false;
        const editorColumns = [];

        const colIsActive = itemFound.data.columnsTableHeader.find(function (element) {
          return element.field === 'IsActive';
        });
        if (colIsActive) {
          editorColumns.push('IsActive');
          colIsActive.editable = true;
        }
        const colNot_Updatable= itemFound.data.columnsTableHeader.find(function (element) {
          return element.field === 'Not_Updatable';
        });
        if (colNot_Updatable) {
          editorColumns.push('Not_Updatable');
          colNot_Updatable.editable = true;
        }
        this.customDesignArray.push(
          {
            vcr: 'vcrAboveGrid',
            type: CustomDesignItemType.FIELD,
            columnName: 'C_BPartner_ID',
            cssClass: 'p-lg-3 p-md-6 p-col-12'
          },
          {
            vcr: 'vcrTableSouscriptions',
            type: CustomDesignItemType.GRID,
            tableName: 'Z_Subscription_Management Table',
            cssClass: 'p-col-12',
            shouldSelectFirst: false,
            editorColumns: editorColumns,
            frameworkComponents: this.frameworkComponents,
            isFitResize: true
          },
          {
            vcr: 'vcrUnderGrid',
            type: CustomDesignItemType.FIELD,
            columnName: 'M_Product_ID',
            label: this.translateService.instant('specificWindow.subscription-management.label.product'),
            cssClass: 'p-lg-3 p-md-6 p-col-12'
          },
          {
            vcr: 'vcrUnderGrid',
            type: CustomDesignItemType.FIELD,
            columnName: 'Z_Create_Souscription',
            cssClass: 'p-lg-3 p-md-6 p-col-12'
          }
        );
      }

      const item: DynamicComponent = {
        container: this,
        DOMParentComponent: this,
        component: 'SpecificWindowUiComponent',
        cssClass: 'p-col-12',
        isCssOnComponent: false,
        tabId: this.formId,
        gridPaginator: false
      };
      this.windowFactory.newEventHandler({
        type: IupicsTypeEvent.showSpecificWindow,
        item: item
      });
    });
  }
  initCell(cellInfo) {
    if (cellInfo && cellInfo.node) {
      cellInfo.node.data[cellInfo.column.colId] = cellInfo.node.data[cellInfo.column.colId] === 'Y' ? 'N' : 'Y';
    }
  }
  updateLine(e, cellInfo?: any) {
    if (!cellInfo || (cellInfo.event && cellInfo.event.target && cellInfo.event.target.tagName === 'INPUT')) {
      if (e) {
        e.stopPropagation();
      }
      let subscriptionId;
      // récupération des infos de la cellule
      if (
        cellInfo &&
        cellInfo.node &&
        cellInfo.node.data &&
        cellInfo.node.data['C_Subscription_ID'] !== null &&
        cellInfo.node.data['C_Subscription_ID'] !== undefined
      ) {
        subscriptionId = cellInfo.node.data['C_Subscription_ID'];
      }
      // Check d'un processus déjà en cours pour cette celulle
      if (this.callMap.get(subscriptionId) !== undefined) {
        if (cellInfo && cellInfo.event.target.tagName === 'INPUT') {
          cellInfo.event.target.checked = !cellInfo.event.target.checked;
        }
        return;
      } else {
        this.initCell(cellInfo);
      }
      // Init des paramètres à passer au processus
      const po = cellInfo.node.data;
      this.callMap.set(subscriptionId, null);
      if (this.callMap.size === 0) {
        this.updateLoading(true);
      }
      if (this.callMap.get(subscriptionId) === null) {
        const id = po['C_Subscription_ID'] instanceof Object ? po['C_Subscription_ID'].id : po['C_Subscription_ID'];
        const dataReformat = Object.assign({}, po);
        Object.keys(dataReformat).forEach((key) => {
          if (dataReformat[key] instanceof Object) {
            dataReformat[key] = dataReformat[key].id;
          }
        });
        this.callMap.set(
          subscriptionId,
          this.poService.save('C_Subscription', dataReformat, id).subscribe(
            (success) => {
              if (this.callMap.get(subscriptionId)) {
                this.callMap.get(subscriptionId).unsubscribe();
                this.callMap.delete(subscriptionId);
                if (this.callMap.size === 0) {
                  this.updateLoading(false);
                }
                if (!success) {
                  this.refreshGrids();
                }
              }
            },
            (err) => {
              this.callMap.get(subscriptionId).unsubscribe();
              this.callMap.delete(subscriptionId);
              if (this.callMap.size === 0) {
                this.updateLoading(false);
                this.refreshGrids();
              }
            }
          )
        );
      }
      this.tablesForm.pop();
    }
  }

  notifyFromCellClicked(gridView: GridViewUiComponent, event: any) {
    if (event && event.column.colId !== null && event.column.colId !== undefined) {
      if (['IsActive','Not_Updatable'].includes(event.column.colId)) {
        this.updateLine(event.event, event);
      }
    }
  }

  onEndProcess() {
    this.refreshGrids();
  }
}
