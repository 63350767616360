<div class="iu-specific-window one-management" [ngClass]="{ 'iu-specific-window-container': !isModal }">
  <p-progressBar
    *ngIf="!isModal && isLoading"
    [mode]="'indeterminate'"
    [value]="0"
    [showValue]="false"
    [styleClass]="'loading-progressbar'"
  ></p-progressBar>
  <div class="ui-g-12">
    <div class="ui-g-12">
      <ng-template #filter></ng-template>
    </div>

    <div class="ui-g-12">
      <div class="one-title">
        <img src="assets/themes/iupics/img/logo-one.png" alt="ONE" srcset="assets/themes/iupics/img/logo-one.png 2x" />

        <b>{{ 'OneManagement.title' | translate }}</b>
      </div>
      <div class="one-tabview one-component">
        <ul role="tablist" class="one-tabview-nav">
          <li
            role="presentation"
            *ngFor="let tab of tabs; let i = index"
            [ngClass]="{ 'one-highlight': selectedTab.title === tab.title }"
          >
            <a role="tab" pripple="" class="one-tabview-nav-link one-ripple" tabindex="0" (click)="selectTab($event, tab)"
              ><span class="one-tabview-title">{{ tab.title }}</span
              ><span class="one-ink"></span
            ></a>
          </li>
          <li class="one-tabview-ink-bar" style="width: 82px; left: 0px"></li>
        </ul>
        <div class="one-tabview-panels">
          <div role="tabpanel" class="one-tabview-panel">
            <div class="ui-g-12 one-panel-content">
              <p *ngIf="selectedTab && selectedTab.content">
                {{ selectedTab.content }}
              </p>
              <div class="ui-g-12" [hidden]="selectedTab.id !== 'synchronizePO'">
                <ng-template #synchronizePO></ng-template>
              </div>
              <div class="ui-g-12" [hidden]="selectedTab.id !== 'declareActivity'">
                <ng-template #declareActivity></ng-template>
              </div>
              <div class="ui-g-12" [hidden]="selectedTab.id !== 'requestSubsidy'">
                <ng-template #requestSubsidy></ng-template>
              </div>
              <iu-button-ui
                [isReadOnly]="isLoading"
                class="ui-g-12 ui-md-4 ui-lg-2 one-button"
                (click)="selectedTab?.action()"
                label="{{ 'process.execute' | translate }}"
              >
              </iu-button-ui>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
