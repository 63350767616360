import { Component, ComponentFactoryResolver, OnInit } from '@angular/core';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { InfoDialogType } from '@iupics-components/specific/window/info-dialog/info-dialog.component';
import GridViewUiComponent from '@iupics-components/standard/grid/grid-view-ui/grid-view-ui.component';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { DynamicComponent } from '@iupics-manager/models/dynamic-component';
import { Global } from '@iupics-manager/models/global-var';
import { IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IupicsMenuType } from '@web-desktop/models/menu-item-ui';
import SpecificWindowUiComponent from '../specific-window-ui/specific-window-ui.component';
import { ProgressBarModule } from 'primeng/progressbar';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SidebarModule } from 'primeng/sidebar';
import { NgClass, NgIf } from '@angular/common';
import { ApizGridHeadlessComponent } from '@iupics/apiz-grid';
import ModalUiComponent from '@iupics-components/standard/layouts/modal-ui/modal-ui.component';

@Component({
  selector: 'iu-remise-buchettes-anf',
  templateUrl: './remise-buchettes-anf.component.html',
  styleUrls: ['./remise-buchettes-anf.component.scss'],
  standalone:true,
  imports: [
    ProgressBarModule,
    ScrollPanelModule,
    SidebarModule,
    NgIf,
    TranslateModule,
    ApizGridHeadlessComponent,
    NgClass,
    ModalUiComponent,
  ],
})
export class RemiseBuchettesAnfComponent extends SpecificWindowUiComponent implements OnInit {
  constructor(
    private uiCreatorService: UICreatorService,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    const champs = this.dataContainers.find(datacontainer => datacontainer.data.columnName === 'AfficheMembreBuchettes');
    champs.data.label = this.translateService.instant('specificWindow.RemiseBuchetteAnf.checkBoxLabel');
    this.setDataContainersValueWithChangedStore();
  }

  processConfirmesRemisBuchettesAnf(item) {
    Global.infoDialog.message = {
      summary: this.translateService.instant('infodialog.dialogs.checkBefore.close.title'),
      detail: this.translateService.instant('specificWindow.RemiseBuchetteAnf.confirmMessage')
    };

    Global.infoDialog.dialogType = InfoDialogType.CONFIRM_YESNO;
    Global.infoDialog.showInfoDialog();
    const confirm = Global.infoDialog.confirm.subscribe((e: any) => {
      const LengthOfRowSelected = this.gridViews[0].GridTabInfinityScrollUiComponent.grid.api.getSelectedRows().length;
      if (LengthOfRowSelected < 1) {
        this.messageManager.newMessage(
          new IupicsMessage(this.translateService.instant('generic.warning'), 'No rows selected', 'error')
        );
      } else {
        let button = this.getDatacontainer('ConfirmesRemisBuchettes');
        if (button) {
          button.isReadOnly = true;
        }
        this.executeProcess(item.processId);
      }
    });
    const cancel = Global.infoDialog.cancel.subscribe(e => {
      // ANNULATION
      confirm.unsubscribe();
      cancel.unsubscribe();
    });
  }

  onEndProcess() {
    let button = this.getDatacontainer('ConfirmesRemisBuchettes');
    if (button) {
      button.isReadOnly = false;
    }
    this.clearAllGridsSelection();
    this.setDatacontainerValue('AfficheMembreBuchettes', 'N');
    // this.refreshGrids(this.dataStore, false);
    this.refreshGrids(this.dataStore, false);
  }

  notifyFromDataChange(item) {
    if (item.data.columnName === 'AfficheMembreBuchettes') {
      this.clearAllGridsSelection();
    }
    this.notifyFromRowSelected(false);
    super.notifyFromDataChange(item);
  }

  processzoom() {
    const selections = this.dataStore.data['selections'].find(
      grid => grid['AD_FormDetail_ID'] === (<GridViewUiComponent>this.gridViews[0]).data['AD_FormDetail_ID']
    );
    if (selections && selections.selection.length > 0) {
      selections.selection.forEach(selection => {
        this.subscriptions.push(
          this.uiCreatorService.zoomAcross('C_BPartner', 'C_BPartner_ID', selection['C_BPartner_ID']).subscribe(dataWs => {
            if (dataWs && dataWs.length > 0) {
              this.subscriptions.push(this.uiCreatorService.getIupicsMenus().subscribe(
                menus=>{
                 /* let menu= menus.find(menu => (menu.action_id === parseInt(dataWs[0].Window_ID, 10)) && menu.menu_type === IupicsMenuType.WINDOW);

                  if(!menu){
                    menu= menus.find(menu => [123
                      ,1000225
                      ,1000111].includes(menu.action_id) && menu.menu_type === IupicsMenuType.WINDOW);
                  }
                  if(menu){
                    dataWs[0].Window_ID=menu.action_id;
                  }
                  //TODO::#170302 unused
                  */
              this.zoomInfo = {
                // tableName: this.data.detailZoom.tableName,
                windowId: dataWs[0].Window_ID,
                dataUUID: dataWs[dataWs.length - 1]['Record_ID'],
                record_id: selection['C_BPartner_ID'],
                children: dataWs.length > 1 ? dataWs.splice(0, dataWs.length - 1) : null
              };
              this.subscriptions.push(
                this.uiCreatorService.getWindow(parseInt(dataWs[dataWs.length - 1]['Window_ID'], 10)).subscribe(tabs => {
                  const item: DynamicComponent = {
                    container: this.container,
                    DOMParentComponent: this.container,
                    linkedComponents: [this],
                    component: 'EditViewUiComponent',
                    cssClass: 'iupics-blade-content',
                    isCssOnComponent: false,
                    tabId: tabs[0].tabId,
                    zoomInfo: this.zoomInfo
                  };
                  this.componentEmitter.emit({
                    type: IupicsTypeEvent.showEditView,
                    item: item
                  });
                })
              );

              Global.workspace.openTargetSearch({
                cat: { id: parseInt(dataWs[0].Window_ID, 10) },
                source: { id: selection['C_BPartner_ID'] !== -1 ? dataWs[0].Record_ID : 'newRecord' } // dataWs[0].Record_ID
              });
            }
            ));

            }
          })
        );
      });
    }
  }
}
