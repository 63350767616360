<div
  class="iu-specific-window"
  [ngClass]="{ 'iu-specific-window-container': !isModal }"
>
  <p-progressBar
    *ngIf="!isModal && isLoading"
    [mode]="'indeterminate'"
    [value]="0"
    [showValue]="false"
    [styleClass]="'loading-progressbar'"
  ></p-progressBar>
  <div class="ui-g-12">
    <p class="help" [innerHTML]="helpReplaced"></p>
  </div>
  <p-scrollPanel [style]="{ width: '100%' }">
    <ng-template iuDynamicContainer></ng-template>
    <div class="iu-specific-btn-container">
      <iu-button-ui
        *ngIf="isModal"
        class="iu-btn btn-process ui-g-12 ui-md-3 ui-lg-2"
        [ngClass]="{
          disabledButton: isLoading === true
        }"
        (click)="$event.preventDefault(); $event.stopPropagation(); showModal()"
        label="{{ 'infodialog.ok' | translate }}"
        icon="fa fa-check"
      >
      </iu-button-ui>
      <ng-template #vcrButtons></ng-template>
      <iu-button-ui
        *ngIf="isModal"
        class="iu-btn btn-cancel ui-g-12 ui-md-3 ui-lg-2"
        (click)="
          $event.preventDefault();
          $event.stopPropagation();
          closeModalEmitter.emit()
        "
        label="{{ 'process.cancel' | translate }}"
        icon="fa fa-ban"
      >
      </iu-button-ui>
    </div>
  </p-scrollPanel>
</div>
<!--<ng-container *ngIf="displaySearch">
  <iu-modal-ui
    [title]="this.searchLinkedComponent.autoComplete.label"
    [hasCloseBtn]="true"
    (closeModalEmitter)="displaySearch = false"
  >
    <div class="autocomplete-universal-filter">
      <iu-universal-filter-ui
        [tabId]="-1"
        [columns]="searchLinkedComponent.autoComplete.data.searchColumns"
        [gridTab]="gridTab"
      ></iu-universal-filter-ui>
    </div>
    <iu-grid-tab-infinity-scroll-ui
      #gridTab
      (searchEmitter)="
        this.searchLinkedComponent.setSearchSelectItem($event);
        displaySearch = false
      "
      [data]="this.searchLinkedComponent.autoComplete.data"
      [isSearch]="true"
      [dataStored]="this.searchLinkedComponent.autoComplete.dataStored"
    ></iu-grid-tab-infinity-scroll-ui>
  </iu-modal-ui>
</ng-container>
TODO::#170302 they remove this from STD
-->

<div class="specificCustoModal" [ngClass]="{ hidden: !displayCustoModal }">
  <iu-modal-ui
    [angularStyle]="{ 'iu-modal': { overflow: 'inherit' } }"
    class="modal-overflow-inherit"
    title=""
    [isModalDisplay]="displayCustoModal"
    (closeModalEmitter)="
      updateModalDisplay({ key: 'displayCustoModal', value: false })
    "
    ><ng-template #vcrChoix></ng-template>
    <div class="iu-specific-btn-container">
      <a
        class="iu-btn btn-cancel"
        (click)="updateModalDisplay({ key: 'displayCustoModal', value: false })"
      >
        <i class="fa fa-ban"></i>{{ "generic.cancel" | translate }}
      </a>
      <ng-template #vcrButtonCreer></ng-template>
    </div>
  </iu-modal-ui>
</div>
