<div class="iu-specific-window iu-specific-window-container">
  <p-progressBar
    *ngIf="!isModal && isLoading"
    [mode]="'indeterminate'"
    [value]="0"
    [showValue]="false"
    [styleClass]="'loading-progressbar'"
  ></p-progressBar>
  <a
    *ngIf="vcrOptional.length > 0"
    class="settings"
    (click)="isSidebarOpen = !isSidebarOpen"
    ><i class="fas fa-cog"></i
  ></a>
  <p-scrollPanel [style]="{ width: '100%' }">
    <div class="ui-g-12 fields">
      <div class="firstContainer">
        <div class="participant-buttons-container">
          <iu-button-ui
            *ngIf="!this.isCadreFed"
            [isReadOnly]="buttonIsInscritReadOnly"
            class="p-col-12 p-md-6 p-lg-6"
            (click)="updateAll($event, 'IsInscrit')"
            label="            {{
              (buttonIsInscrit
                ? 'specificWindow.participantManagement.action.inscrit'
                : 'specificWindow.participantManagement.action.notInscrit'
              ) | translate
            }}"
          >
          </iu-button-ui>
          <iu-button-ui
            [isReadOnly]="buttonIsPresentReadOnly"
            class="p-col-12 p-md-6 p-lg-6"
            (click)="updateAll($event, 'IsPresent')"
            label="            {{
              (buttonIsPresent
                ? 'specificWindow.participantManagement.action.present'
                : 'specificWindow.participantManagement.action.notPresent'
              ) | translate
            }}"
          >
          </iu-button-ui>
          <iu-button-ui
            *ngIf="!this.isCadreFed"
            [isReadOnly]="buttonIsPaidReadOnly"
            class="p-col-12 p-md-6 p-lg-6"
            (click)="updateAll($event, 'IsPaid')"
            label="            {{
              (buttonIsPaid
                ? 'specificWindow.participantManagement.action.paid'
                : 'specificWindow.participantManagement.action.notPaid'
              ) | translate
            }}"
          >
          </iu-button-ui>
        </div>
        <div class="ui-g-12 ui-md-6 ui-lg-2 p-col-6 participant-right-container">
          <ng-template #vcrProject></ng-template>
          <div class="participantInfoContainer">
            <div class="participantInfoLine ui-g">
              <span> Nombre d'inscrit : {{ this.nbInscrit }}</span>
            </div>
            <div class="participantInfoLine">
              <span> Nombre de présents : {{ this.nbPresent }}</span>
            </div>
            <div class="participantInfoLine">
              <span> Nombre de payé : {{ this.nbPaid }}</span>
            </div>
          </div>
        </div>
      </div>
      <ng-template #firstGrid></ng-template>
    </div>
  </p-scrollPanel>
  <p-sidebar
    [(visible)]="isSidebarOpen"
    [baseZIndex]="6000"
    position="right"
    styleClass="ui-sidebar-md optionalFilterPanel"
  >
    <h1 class="iu-notification-center-title">
      Optional filters
    </h1>
    <div class="optionalFields">
      <ng-template #optional></ng-template>
    </div>
  </p-sidebar>
</div>
<!--<ng-container *ngIf="displaySearch">
  <iu-modal-ui
    [title]="this.searchLinkedComponent.autoComplete.label"
    [hasCloseBtn]="true"
    (closeModalEmitter)="closeModalSearch()"
  >
    <div class="autocomplete-universal-filter">
      <iu-universal-filter-ui
        [tabId]="-1"
        [columns]="searchLinkedComponent.autoComplete.data.searchColumns"
        [gridTab]="gridTab"
      ></iu-universal-filter-ui>
    </div>
    <iu-grid-tab-infinity-scroll-ui
      #gridTab
      (searchEmitter)="
        this.searchLinkedComponent.setSearchSelectItem($event);
        displaySearch = false
      "
      [data]="this.searchLinkedComponent.autoComplete.data"
      [isSearch]="true"
      [dataStored]="this.searchLinkedComponent.autoComplete.dataStored"
    ></iu-grid-tab-infinity-scroll-ui>
  </iu-modal-ui>
</ng-container>-->
<div class="specificModal" [ngClass]="{ hidden: !displayFormUI }">
  <iu-modal-ui
    [title]="specificWindowTitle"
    [isModalDisplay]="displayFormUI"
    (closeModalEmitter)="
      updateModalDisplay({ key: 'displayFormUI', value: false })
    "
  >
    <ng-template #vcrSpecific></ng-template>
  </iu-modal-ui>
</div>
