import { Component, ComponentFactoryResolver, OnInit } from '@angular/core';
import { CustomDesignItemType } from '@iupics-components/models/custom-design';
import AutocompleteUiComponent from '@iupics-components/standard/fields/autocomplete-ui/autocomplete-ui.component';
import ButtonUiComponent from '@iupics-components/standard/fields/button-ui/button-ui.component';
import GridViewUiComponent from '@iupics-components/standard/grid/grid-view-ui/grid-view-ui.component';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { DynamicComponent } from '@iupics-manager/models/dynamic-component';
import { IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import ProcessUiComponent from '../process-ui/process-ui.component';
import SpecificWindowUiComponent, { RowSelectionType } from '../specific-window-ui/specific-window-ui.component';
import { NgClass } from '@angular/common';
import { ProgressBarModule } from 'primeng/progressbar';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { DynamicContainerDirective } from '@iupics-util/directives/dynamic-container.directive';
import ModalUiComponent from '@iupics-components/standard/layouts/modal-ui/modal-ui.component';
import {SidebarModule} from "primeng/sidebar";

@Component({
  selector: 'iu-event-search',
  templateUrl: './event-search.component.html',
  styleUrls: ['./event-search.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    ProgressBarModule,
    ScrollPanelModule,
    DynamicContainerDirective,
    ButtonUiComponent,
    ModalUiComponent,
    // UniversalFilterUiComponent,
    // GridTabInfinityScrollUiComponent,
    TranslateModule,
    SidebarModule,
  ],
})
export class EventSearchComponent extends SpecificWindowUiComponent implements OnInit {
  SumSelectedRows = 0;
  processUiComponent: ProcessUiComponent;
  ButtonUiComponent: ButtonUiComponent;
  constructor(
    messageManager: MessageManagerService,
  ) {
    super();
    this.isModal = false;
  }
  ngOnInit() {
    this.customDesignArray = [
      {
        vcr: 'vcr',
        type: CustomDesignItemType.FIELD,
        columnName: 'AD_Role_ID',
        cssClass: 'p-col-12 p-md-6 p-lg-3'
      },
      {
        vcr: 'vcr',
        type: CustomDesignItemType.FIELD,
        columnName: 'C_ProjectType_ID',
        cssClass: 'p-col-12 p-md-6 p-lg-3'
      },
      {
        vcr: 'vcr',
        type: CustomDesignItemType.FIELD,
        columnName: 'S_Training_ID',
        cssClass: 'p-col-12 p-md-6 p-lg-3'
      },
      {
        vcr: 'vcr',
        type: CustomDesignItemType.FIELD,
        columnName: 'S_Training_Class_ID',
        cssClass: 'p-col-12 p-md-6 p-lg-3'
      },
      {
        vcr: 'vcr',
        type: CustomDesignItemType.FIELD,
        columnName: 'Value',
        cssClass: 'p-col-12 p-md-6 p-lg-3'
      },
      {
        vcr: 'vcr',
        type: CustomDesignItemType.FIELD,
        columnName: 'Name',
        cssClass: 'p-col-12 p-md-6 p-lg-3'
      },
      {
        vcr: 'vcr',
        type: CustomDesignItemType.FIELD,
        columnName: 'AD_Org_ID',
        cssClass: 'p-col-12 p-md-6 p-lg-3'
      },
      {
        vcr: 'vcr',
        type: CustomDesignItemType.FIELD,
        columnName: 'Z_PeriodeForm_ID',
        cssClass: 'p-col-12 p-md-6 p-lg-3'
      },
      {
        vcr: 'vcr',
        type: CustomDesignItemType.GRID,
        tableName: 'View Event Table',
        cssClass: 'p-col-12'
      }
    ];
    const item: DynamicComponent = {
      container: this,
      DOMParentComponent: this,
      component: 'SpecificWindowUiComponent',
      cssClass: 'p-col-12',
      isCssOnComponent: false,
      tabId: this.formId,
      gridPaginator: false
    };
    if (this.sourceComponent && this.sourceComponent.dataStored) {
      if (!this.dataStore) {
        this.dataStore = this.store.newSpecificWindowData(this.formId, null);
        this.dataStore.data['AD_Org_ID'] = this.sourceComponent.dataStored.data['AD_Org_ID'];
      }
    }
    this.windowFactory.newEventHandler({
      type: IupicsTypeEvent.showSpecificWindow,
      item: item
    });
  }

  /**
   * Implementation de la sélection d'une cellule
   * @param gridView grid sur laquelle s'est fait la sélection
   */
  notifySelect(gridView: GridViewUiComponent, row: any) {
    const data = row?.data[0];
    if (this.isModal && data) {
      this.setRowSelection(gridView.data.columnName, RowSelectionType.SINGLE);
      let identifiant = null;
      if (data['Data_UUID']) {
        identifiant = data['Data_UUID'].split(',')[0];
      }
      if (!identifiant) {
        identifiant = gridView.data.columnsDetails.keys[0];
      }
      const sourceFieldName = this.sourceComponent.data.columnName;
      if (sourceFieldName) {
        const event = { data: { Data_UUID: sourceFieldName + ',' + data[identifiant] } };
        (<AutocompleteUiComponent>this.sourceComponent).setSearchSelectItem(event);
        this.closeModalEmitter.emit();
      }
    }
  }

  selectEvent(p_C_Project_ID: number) {
    this.dataStore.data.selections[0].selection = this.gridViews[0].GridTabInfinityScrollUiComponent.grid.api.getSelectedRows();
    const columnName = this.sourceComponent.data.columnName;

    if (columnName && p_C_Project_ID) {
      const event = { data: { Data_UUID: columnName + ',' + p_C_Project_ID } };
      (<AutocompleteUiComponent>this.sourceComponent).setSearchSelectItem(event);
    }
  }
}
