import { Component, ElementRef, OnInit, Output, ViewChild, ViewContainerRef,forwardRef } from '@angular/core';
import {FormArray, FormBuilder, FormGroup, ReactiveFormsModule} from '@angular/forms';
import {
  CompiereDataGridFilterType,
  CompiereDataGridSortModelType,
  CompiereDataGridType,
  DataStoreRequest
} from '@compiere-ws/models/compiere-data-json';
import { ProcessPingInfo } from '@compiere-ws/models/process-ping-info';
import { LocationService } from '@compiere-ws/services/compiere-location/location.service';
import { PoService } from '@compiere-ws/services/po/po.service';
import { CustomDesignItemType } from '@iupics-components/models/custom-design';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { DynamicComponent } from '@iupics-manager/models/dynamic-component';
import { IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { TranslateModule } from '@ngx-translate/core';
import * as moment from 'moment';
import SpecificWindowUiComponent from '../specific-window-ui/specific-window-ui.component';
import { ProgressBarModule } from 'primeng/progressbar';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SidebarModule } from 'primeng/sidebar';
import {NgClass, NgForOf, NgIf} from '@angular/common';
import { ApizGridHeadlessComponent } from '@iupics/apiz-grid';
import InputLocationUiComponent from "@iupics-components/standard/fields/input-location-ui/input-location-ui.component";
import CalendarUiComponent from "@iupics-components/standard/fields/calendar-ui/calendar-ui.component";
import ButtonUiComponent from "@iupics-components/standard/fields/button-ui/button-ui.component";
import {TooltipModule} from "primeng/tooltip";
import {ButtonModule} from "primeng/button";

@Component({
  selector: 'iu-read-create-step',
  templateUrl: './read-create-step.component.html',
  styleUrls: ['./read-create-step.component.scss'],
  standalone:true,
  imports: [
    ProgressBarModule,
    ScrollPanelModule,
    SidebarModule,
    NgIf,
    TranslateModule,
    ApizGridHeadlessComponent,
    NgClass,
    ReactiveFormsModule,
    InputLocationUiComponent,
    forwardRef(() => CalendarUiComponent),
    ButtonUiComponent,
    NgForOf,
    CalendarUiComponent,
    TooltipModule,
    ButtonModule,
  ],
})
export class ReadCreateStepComponent extends SpecificWindowUiComponent implements OnInit {

  @ViewChild('bottom', { read: ViewContainerRef, static: true })
  vcrBottom: ViewContainerRef;

  @Output()
  etapes: Etape[] = [];

  data;
  dataStored;
  additionalParams = {};

  etapeLabel = 'specificWindow.readStep.noStep';
  dateStartLabel = 'specificWindow.readStep.dateStart';
  dateFinishLabel = 'specificWindow.readStep.dateFinish';
  locationLabel = 'specificWindow.readStep.locationLabel';

  formGroup: FormGroup;
  array;

  displayLocation = false;
  enableSize = 1000;

  tableName = 'X_Camp_Etapes';
  catchError = false;

  constructor(
    private fb: FormBuilder,
    private locationService: LocationService,
    messageManager: MessageManagerService,
    private elementRef: ElementRef,
    private po: PoService
  ) {
    super();
    this.customDesignArray.push(
      {
        vcr: 'vcrBottom',
        type: CustomDesignItemType.FIELD,
        columnName: 'Save',
        cssClass: 'p-col-12 p-md-3 p-lg-3'
      },
      {
        vcr: 'vcrBottom',
        type: CustomDesignItemType.FIELD,
        columnName: 'cancel',
        cssClass: 'p-col-12 p-md-3 p-lg-3'
      }
    );

    this.formGroup = this.fb.group({
      array: this.fb.array([])
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.parentComponent.customFormModalBodyCss = {
      'iu-modal-body': { padding: '0' }
    };
    this.elementRef.nativeElement.style.height = 'height: calc(100% - 20px)';
    this.data = { IsGoogleRestricted: false, GoogleRestrictedLogic: "@IsForeignCamp@='N'" };
    this.dataStored = {};
    const item: DynamicComponent = {
      container: this,
      DOMParentComponent: this,
      component: 'SpecificWindowUiComponent',
      cssClass: 'p-col-12',
      isCssOnComponent: false,
      tabId: this.formId,
      gridPaginator: false
    };
    this.windowFactory.newEventHandler({
      type: IupicsTypeEvent.showSpecificWindow,
      item: item
    });

    this.refreshEtape();
  }

  addNewEtape() {
    this.array = this.formGroup.get('array') as FormArray;
    this.array.push(this.createItem());
  }

  createItem(): FormGroup {
    if (this.etapes.length === 0) {
      let newValue1;
      if (this.getCurrentContext()['DateDebutFictive']) {
        newValue1 = new Date(this.getCurrentContext()['DateDebutFictive']);
      }
      let newValue;
      if (this.getCurrentContext()['DateFinFictive']) {
        newValue = new Date(this.getCurrentContext()['DateFinFictive']);
      }
      const etape: Etape = {
        id: undefined,
        date_depart: newValue1,
        date_departST: this.getCurrentContext()['DateDebutFictive'],
        date_arrive: newValue,
        date_arriveST: this.getCurrentContext()['DateFinFictive'],
        netapes: this.etapes.length + 1,
        ville_depart: undefined
        // ville_arrive: undefined,
      };
      this.etapes.push(etape);
      return this.fb.group(etape);
    } else {
      let newValue;
      if (this.getCurrentContext()['DateFinFictive']) {
        newValue = new Date(this.getCurrentContext()['DateFinFictive']);
      }
      const etape: Etape = {
        id: undefined,
        date_depart: this.etapes[this.etapes.length - 1].date_arrive,
        date_departST: this.etapes[this.etapes.length - 1].date_arriveST,
        date_arrive: newValue,
        date_arriveST: this.getCurrentContext()['DateFinFictive'],
        netapes: this.etapes.length + 1,
        ville_depart: undefined
        // ville_arrive: undefined,
      };
      this.etapes.push(etape);
      return this.fb.group(etape);
    }
  }

  set_ville_Depart(item, index) {
    this.etapes[index].ville_depart = item;
  }

  deleteStep(index) {
    if (!this.etapes[index].id) {
      this.etapes.splice(index, 1);
    } else {
      this.po.delete(this.tableName, this.etapes[index].id).subscribe((res) => {
        this.etapes.splice(index, 1);
      });
    }
  }

  processSave(item) {
    this.additionalParams['selections'] = this.etapes.map((e) => {
      return {
        date_depart: e.date_departST,
        date_arrive: e.date_arriveST,
        id: e.id,
        netapes: e.netapes,
        ville_depart: e.ville_depart
      };
    });
    this.additionalParams['C_Project_ID'] = this.getCurrentContext()['C_Project_ID'];
    if (this.additionalParams['selections']) {
      const etapesDateError = this.additionalParams['selections'].filter(
        (c) => c.date_depart === undefined || c.date_depart === null || c.date_arrive === undefined || c.date_arrive === null
      );
      const etapesDateAnteriorError = this.additionalParams['selections'].filter((c) => c.date_depart > c.date_arrive);
      const etapesLocationError = this.additionalParams['selections'].filter(
        (c) => c.ville_depart === undefined || c.ville_depart === null
      );
      if (etapesDateError.length > 0) {
        this.messageManager.newMessage(
          new IupicsMessage(this.translateService.instant('generic.warning'), 'Des dates doivent être renseignées.', 'warning')
        );
        return;
      }
      if (etapesDateAnteriorError.length > 0) {
        this.messageManager.newMessage(
          new IupicsMessage(
            this.translateService.instant('generic.warning'),
            'Date départ doit être antérieure à date arrivée',
            'warning'
          )
        );
        return;
      }
      if (etapesLocationError.length > 0) {
        this.messageManager.newMessage(
          new IupicsMessage(
            this.translateService.instant('generic.warning'),
            'Le lieu de départ ne peut pas être vide.',
            'warning'
          )
        );
        return;
      }
      this.catchError = false;
      this.executeProcess(item.processId, this.additionalParams);
    } else {
      this.messageManager.newMessage(
        new IupicsMessage(this.translateService.instant('generic.warning'), 'Aucun étape ajouté ou modifié', 'warning')
      );
    }
  }
  get getArray() {
    return this.formGroup.get('array') as FormArray;
  }
  addItem(item) {
    this.etapes.push(item);
    this.array.push(this.fb.control(false));
  }
  removeItem() {
    this.etapes.pop();
    this.array.removeAt(this.array.length - 1);
  }

  changeDefaultDate(date, index, isStart) {
    let newValue;
    if (date) {
      newValue = moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS');
      newValue = newValue.substring(0, 26) + newValue.substring(27, newValue.length);
    }
    if (date) {
      if (isStart) {
        this.etapes[index].date_depart = date;
        this.etapes[index].date_departST = newValue;
      } else {
        this.etapes[index].date_arrive = date;
        this.etapes[index].date_arriveST = newValue;
      }
    }
    if (isStart) {
      this.checkSequence(index);
    }
  }

  onEndProcess(ping: ProcessPingInfo = null) {
    if (!this.catchError) {
      this.refreshEtape();
    }
    if (ping && ping.AD_Process_ID && ping.AD_Process_ID.id) {
      setTimeout(() => {
        this.updateButtonReadOnly(ping.AD_Process_ID.id, false);
      }, 3000);
    }
  }
  notifyFromLogs(log) {
    if (log.startsWith('Erreur:')) {
      this.catchError = true;
    }
  }
  refreshEtape() {
    if (this.dataStore.data['C_Project_ID']) {
      const request: DataStoreRequest = {
        windowId: null,
        parent_constraint: '',
        compiereRequest: {
          windowType: CompiereDataGridType.TABLE,
          startRow: 0,
          endRow: 0,
          tableName: this.tableName,
          filterModel: {
            C_Project_ID: {
              filterType: CompiereDataGridFilterType.SET,
              values: [this.dataStore.data['C_Project_ID']],
              operators: [OperatorFilterType.EQUALS]
            }
          },
          sortModel: [
            {
              colId: 'NETAPES',
              sort: CompiereDataGridSortModelType.ASC
            }
          ]
        }
      };
      this.store.getDataGrid(request).subscribe((response) => {
        this.etapes = [];
        response.data.forEach((item) => {
          const etape: Etape = {
            id: item['X_Camp_Etapes_ID'],
            date_depart: item['Date_depart'] ? moment(item['Date_depart']).toDate() : null,
            date_arrive: item['Date_arrive'] ? moment(item['Date_arrive']).toDate() : null,
            date_arriveST: item['Date_arrive'],
            date_departST: item['Date_depart'],
            netapes: item['NEtapes'],
            ville_depart: null
            // ville_arrive: item['VILLE_ARRIVE'],
          };
          this.etapes.push(etape);
          if (item['Ville_depart']) {
            this.locationService.getLocation(item['Ville_depart']).subscribe((response) => {
              // const address = addressData.filter(item => item !== undefined && item !== null && item !== '');
              etape['ville_depart'] = {
                id: parseInt(item['Ville_depart'], 10),
                displayValue: response['City']
              };
            });
          } else {
            etape['ville_depart'] = null;
            this.messageManager.newMessage(
              new IupicsMessage(
                this.translateService.instant('generic.warning'),
                'Des étapes doivent être complétées.',
                'warning'
              )
            );
            return;
          }
        });
        if (this.etapes && this.etapes.length === 0) {
          this.addNewEtape();
        } else {
          const etapesDateError = this.etapes.filter(
            (c) => c.date_depart === undefined || c.date_depart === null || c.date_arrive === undefined || c.date_arrive === null
          );
          const etapesDateAnteriorError = this.etapes.filter((c) => c.date_depart > c.date_arrive);
          let isOK = true;
          if (etapesDateError.length > 0) {
            isOK = false;
          }
          if (etapesDateAnteriorError.length > 0) {
            isOK = false;
          }
          if (!isOK) {
            this.messageManager.newMessage(
              new IupicsMessage(
                this.translateService.instant('generic.warning'),
                'Des étapes doivent être complétées.',
                'warning'
              )
            );
            return;
          }
        }
      });
    }
  }

  checkSequence(index) {
    this.etapes.sort((a, b) => {
      if (a.date_depart < b.date_depart) {
        return -1;
      } else if (a.date_depart < b.date_depart) {
        return 1;
      } else {
        return 0;
      }
    });
    this.reorderSequence();
  }

  reorderSequence() {
    let i = 1;
    this.etapes.forEach((et) => {
      et.netapes = i++;
    });
  }

  processcancel() {
    this.parentComponent.updateModalDisplay({
      key: 'displayFormUI',
      value: false
    });
  }
}

interface Etape {
  id: number;
  netapes: number;
  date_depart: Date;
  date_arrive: Date;
  date_departST?: String;
  date_arriveST?: String;
  ville_depart: {};
  // ville_arrive: {},
}
