<div class="iu-specific-window iu-specific-window-container">
    <p-progressBar
    *ngIf="!isModal && isLoading"
    [mode]="'indeterminate'"
    [value]="0"
    [showValue]="false"
    [styleClass]="'loading-progressbar'"
  ></p-progressBar>
  <p-scrollPanel [style]="{ width: '100%' }">
    <div class="p-col-12 row-fields">
      <ng-template #top></ng-template>
    </div>
  </p-scrollPanel>
</div>
