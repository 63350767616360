import { Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { AbstractDataContainer, AbstractDataContainerCallout } from '@iupics-manager/models/abstract-datacontainer';
import { ContextMenuService } from '@web-desktop/components/workspace/controllers/context-menu/context-menu.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { FormsModule, NgModel } from '@angular/forms';
import { NgClass, NgIf } from '@angular/common';
import PrimeChipsComponent from '@iupics-components/overrided/prime-chips/prime-chips.component';
import { TextLimitPipe } from '@iupics-util/pipes/text-limit/text-limit.pipe';
import { MenuModule } from 'primeng/menu';
import EditTabUiComponent from '@iupics-components/standard/layouts/edit-tab-ui/edit-tab-ui.component';

import { CompiereDataGridFilterType, DataStoreRequest } from '@compiere-ws/models/compiere-data-json';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';

@Component({
  selector: 'iu-input-place-autocomplete-ui',
  templateUrl: './input-place-autocomplete-ui.component.html',
  styleUrls: ['./input-place-autocomplete-ui.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass, PrimeChipsComponent, FormsModule, TextLimitPipe, MenuModule]
})
export class InputPlaceAutocompleteUiComponent extends AbstractDataContainer implements OnInit {

  constructor(
    public elementRef: ElementRef,
    public store: DataStoreService,
    protected connectorService: SecurityManagerService,
    public uiCreatorService: UICreatorService,
    public cmService: ContextMenuService,
    private ngZone: NgZone
  ) {
    super();
  }

  @Input()
  cssGrid: string;
  @Input()
  placeHolder: string;
  @ViewChild('input', { static: true })
  inputRef: ElementRef;
  @ViewChild('opConflict', { static: true })
  opConflict: OverlayPanel;
  dataContainers: AbstractDataContainerCallout;

  @Output()
  fieldValueChange = new EventEmitter<any>();

  ngOnInit() {

    super.ngOnInit();

    if (this.cssClass !== undefined) {
      this.cssGrid = this.cssClass;
    }
    this.cssClass = ' ' + this.cssGrid;
    this.setFieldMandatory();

      const request: DataStoreRequest = {
        windowId: null,
        parent_constraint: '',
        compiereRequest: {
          startRow: 0,
          endRow: 0,
          tableName: 'AD_Message',
          filterModel: {
            Value: {
              filterType: CompiereDataGridFilterType.TEXT,
              values: ['PLACE_AUTOCOMPLETE_COUNTRIES_RESTRICTION'],
              operators: [OperatorFilterType.EQUALS]
            }
          }
        }
      };

      this.store.getDataGrid(request).subscribe(response => {
        const ListCountries = response.data.length > 0 ? response.data[0]['MSGTEXT'].split(',') : [];
        const autocomplete = new google.maps.places.Autocomplete(this.inputRef.nativeElement, { types: ['geocode'] });
        autocomplete.setComponentRestrictions({ country: ListCountries });
        autocomplete.addListener('place_changed', () => {
          this.ngZone.run(() => {
            const place: google.maps.places.PlaceResult = autocomplete.getPlace();
            if (place.formatted_address) {
              super.dataChange(place.formatted_address);
            }
            if (place.geometry === undefined || place.geometry === null) {
              return;
            }
          });
        });
      });
  }

  // #START CUSTO-GCB #170302
  ngAfterViewInit() {
    super.ngAfterViewInit();
    let parentComp = this.DOMParentComponent;
    while (parentComp && !(parentComp instanceof EditTabUiComponent)) {
      parentComp = parentComp.DOMParentComponent;
    }
    if (parentComp instanceof EditTabUiComponent) {
      this.dataContainers = new AbstractDataContainerCallout(parentComp.dataContainers);
    }
  }
  // #END CUSTO-GCB #170302

  dataChange(value: any) {
    if (value === null || value === '') {
      super.dataChange(value);
    }
  }

}
