import {
  Component,
  OnInit,
  ViewChild,
  ViewContainerRef
} from "@angular/core";
import { LocationService } from "@compiere-ws/services/compiere-location/location.service";
import { CustomDesignItemType } from "@iupics-components/models/custom-design";
import EditViewUiComponent from "@iupics-components/standard/layouts/edit-view-ui/edit-view-ui.component";
import { DynamicComponent } from "@iupics-manager/models/dynamic-component";
import { IupicsTypeEvent } from "@iupics-manager/models/iupics-event";
import { TranslateModule } from '@ngx-translate/core';
import SpecificWindowUiComponent from "./../specific-window-ui/specific-window-ui.component";
import { NgClass } from '@angular/common';
import { ProgressBarModule } from 'primeng/progressbar';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { DynamicContainerDirective } from '@iupics-util/directives/dynamic-container.directive';
import ModalUiComponent from '@iupics-components/standard/layouts/modal-ui/modal-ui.component';
import ButtonUiComponent from '@iupics-components/standard/fields/button-ui/button-ui.component';
import { GoogleMapsModule, MapGeocoder, MapGeocoderResponse } from "@angular/google-maps";

@Component({
  selector: "iu-get-map-info-camp",
  templateUrl: "./get-map-info-camp.component.html",
  styleUrls: ["./get-map-info-camp.component.scss"],
  standalone: true,
  imports: [
    NgClass,
    ProgressBarModule,
    ScrollPanelModule,
    DynamicContainerDirective,
    ModalUiComponent,
    TranslateModule,
    ButtonUiComponent,
    GoogleMapsModule,
  ],
})
export class GetMapInfoCampComponent extends SpecificWindowUiComponent implements OnInit {
  @ViewChild("top", { read: ViewContainerRef, static: true })
  vcrTop: ViewContainerRef;

  zoom = 8;
  markerPosition: google.maps.LatLngLiteral;
  lat = 49.926263;
  lng = 5.297816;

  markerOptions: google.maps.MarkerOptions = {
    draggable: true,
  };

  constructor(
      private locationService: LocationService,
      private geocoder: MapGeocoder
  ) {
    super();
    this.isModal = false;
    this.customDesignArray.push(
        {
          vcr: "vcrTop",
          type: CustomDesignItemType.FIELD,
          columnName: "Geo_Location_Lat",
          cssClass: "p-col-6"
        },
        {
          vcr: "vcrTop",
          type: CustomDesignItemType.FIELD,
          columnName: "Geo_Location_Lng",
          cssClass: "p-col-6"
        }
    );
  }

  ngOnInit() {
    super.ngOnInit();
    this.markerPosition = {
      lat: this.lat,
      lng: this.lng,
    };

    const item: DynamicComponent = {
      container: this,
      DOMParentComponent: this,
      component: "SpecificWindowUiComponent",
      cssClass: "p-col-12",
      isCssOnComponent: false,
      tabId: this.formId,
      gridPaginator: false
    };
    this.windowFactory.newEventHandler({
      type: IupicsTypeEvent.showSpecificWindow,
      item: item
    });

    if (this.dataStore.data["Latitude"] && this.dataStore.data["Longitude"]) {
      this.markerPosition.lat = parseFloat(this.dataStore.data["Latitude"]);
      this.markerPosition.lng = parseFloat(this.dataStore.data["Longitude"]);
      this.lat = this.markerPosition.lat;
      this.lng = this.markerPosition.lng;
    } else if (this.dataStore.data["C_Project_Location_ID"]) {
      this.locationService
          .getLocation(this.dataStore.data["C_Project_Location_ID"].id)
          .subscribe(response => {
            const addressData = [
              response["Address1"],
              response["Address2"],
              response["Address3"],
              response["Address4"],
              response["City"],
              response["Postal"]
            ];
            const address = addressData
                .filter(
                    item => item !== undefined && item !== null && item !== ""
                )
                .join(",");
            this.geocoder.geocode({ address }).subscribe((result: MapGeocoderResponse) => {
              if (result.status === "OK" && result.results.length > 0) {
                this.markerPosition.lat = result.results[0].geometry.location.lat();
                this.markerPosition.lng = result.results[0].geometry.location.lng();
                this.lat = this.markerPosition.lat;
                this.lng = this.markerPosition.lng;
                this.updateData();
              }
            });
          });
    }

    this.updateData();
  }

  clickedMarker(label: string, index: number) {
    console.log(`clicked the marker: ${label || index}`);
  }

  mapClicked($event: google.maps.MapMouseEvent) {
    this.markerPosition = {
      lat: $event.latLng.lat(),
      lng: $event.latLng.lng(),
    };
    this.updateData();
  }

  updateData() {
    if (this.markerPosition.lat && this.markerPosition.lng) {
      this.dataStore.data["Geo_Location_Lat"] = parseFloat(
          (
              Math.round(this.markerPosition.lat * Math.pow(10, 7)) / Math.pow(10, 7)
          ).toFixed(7)
      );
      this.dataStore.data["Geo_Location_Lng"] = parseFloat(
          (
              Math.round(this.markerPosition.lng * Math.pow(10, 7)) / Math.pow(10, 7)
          ).toFixed(7)
      );
      this.dataContainers.forEach(datacontainer => {
        datacontainer.dataChange(
            this.dataStore.data[datacontainer.data.columnName]
        );
      });
    }
  }

  save() {
    (<EditViewUiComponent>this.parentComponent)
        .editTabs[0].dataContainers.forEach(datacontainer => {
      if (datacontainer.data.columnName === "Latitude") {
        datacontainer.updateStore(
            this.dataStore.data["Geo_Location_Lat"] ||
            this.dataStore.data["Latitude"]
        );
      } else if (datacontainer.data.columnName === "Longitude") {
        datacontainer.updateStore(
            this.dataStore.data["Geo_Location_Lng"] ||
            this.dataStore.data["Longitude"]
        );
      }
    });
  }
}

// just an interface for type safety.

interface marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
}
