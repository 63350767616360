<p-toolbar>
  <div class="ui-left">
    @if (!isMobile) {
      @if (isZoomTarget) {
        <button
          pButton
          tooltipPosition="top"
          showDelay="250"
          [life]="3000"
          [hideDelay]="250"
          pTooltip="{{ 'menuBarDetails.zoom' | translate }}"
          type="button"
          class="p-menu-bar-button"
          icon="icon-table"
          (mouseup)="changeGridViewVisibility && $event.button === 0 ? changeGridViewVisibility.emit($event) : ''"
          iuKeybinding
          iuKeybinding-event-mode="mouseup"
          [iuKeybinding-active]="activateKeybind && !isSplitView"
          iuKeybinding-code="menu-bar-detail.zoom"
          [iuKeybinding-focusTab]="activeTabID"></button>
      }
      <button
        pButton
        tooltipPosition="top"
        showDelay="250"
        [life]="3000"
        [hideDelay]="250"
        pTooltip="{{ 'menuBarDetails.add' | translate }}"
        class="p-menu-bar-button"
        type="button"
        icon="icon-add"
        [disabled]="!newBtnCondition"
        (mouseup)="newEvent && $event.button === 0 ? newEvent.emit($event) : ''"
        iuKeybinding
        iuKeybinding-event-mode="mouseup"
        [iuKeybinding-active]="activateKeybind && !isSplitView"
        iuKeybinding-code="menu-bar-detail.new"
        [iuKeybinding-focusTab]="activeTabID"
        data-cy="button-newRecord"></button>
      <button
        pButton
        tooltipPosition="top"
        showDelay="250"
        [life]="3000"
        [hideDelay]="250"
        pTooltip="{{ 'menuBarDetails.save' | translate }}"
        type="button"
        class="p-menu-bar-button"
        icon="icon-save3x"
        [disabled]="!saveBtnCondition"
        (mouseup)="saveEvent && $event.button === 0 ? saveEvent.emit($event) : ''"
        iuKeybinding
        iuKeybinding-event-mode="mouseup"
        [iuKeybinding-active]="activateKeybind && !isSplitView"
        iuKeybinding-code="menu-bar-detail.save"
        [iuKeybinding-focusTab]="activeTabID"
        data-cy="button-saveRecord"></button>
      <button
        pButton
        tooltipPosition="top"
        showDelay="250"
        [life]="3000"
        [hideDelay]="250"
        pTooltip="{{ 'menuBarDetails.refresh' | translate }}"
        type="button"
        class="p-menu-bar-button"
        icon="icon-refresh3x"
        [disabled]="!refreshBtnCondition"
        (mouseup)="refreshEvent && $event.button === 0 ? refreshEvent.emit($event) : ''"
        iuKeybinding
        iuKeybinding-event-mode="mouseup"
        [iuKeybinding-active]="activateKeybind && !isSplitView"
        iuKeybinding-code="menu-bar-detail.refresh"
        [iuKeybinding-focusTab]="activeTabID"
        data-cy="button-refresh"></button>
      <button
        pButton
        tooltipPosition="top"
        showDelay="250"
        [life]="3000"
        [hideDelay]="250"
        pTooltip="{{ 'menuBarDetails.delete' | translate }}"
        type="button"
        class="p-menu-bar-button"
        icon="icon-delete"
        [disabled]="!deleteBtnCondition"
        (mouseup)="deleteEvent && $event.button === 0 ? deleteEvent.emit($event) : ''"
        iuKeybinding
        iuKeybinding-event-mode="mouseup"
        [iuKeybinding-active]="activateKeybind && !isSplitView"
        iuKeybinding-code="menu-bar-detail.delete"
        [iuKeybinding-focusTab]="activeTabID"
        data-cy="button-delete"></button>
      <button
        pButton
        tooltipPosition="top"
        showDelay="250"
        [life]="3000"
        [hideDelay]="250"
        pTooltip="{{ 'menuBarDetails.copy' | translate }}"
        type="button"
        class="p-menu-bar-button"
        icon="icon-copy"
        [disabled]="!copyBtnCondition"
        (mouseup)="copyEvent && $event.button === 0 ? copyEvent.emit($event) : ''"
        iuKeybinding
        iuKeybinding-event-mode="mouseup"
        [iuKeybinding-active]="activateKeybind && !isSplitView"
        iuKeybinding-code="menu-bar-detail.copy"
        [iuKeybinding-focusTab]="activeTabID"
        data-cy="button-copy"></button>
      @if (availableProcesses && processesDisplayed.length>0) {
        <button
          pButton
          tooltipPosition="top"
          showDelay="250"
          [life]="3000"
          [hideDelay]="250"
          pTooltip="{{ 'menuBarDetails.process' | translate }}"
          type="button"
          class="p-menu-bar-button open-icon-button processes-btn"
          icon="icon-process-cogs"
          data-cy="button-process"
          (mouseleave)="hideProcessList('button')"
          (mouseenter)="showProcessList()"></button>
      }
    } @else {
      <button
        pButton
        data-cy="menuBar-showButtons-btn"
        tooltipPosition="top"
        showDelay="250"
        [life]="3000"
        [hideDelay]="250"
        pTooltip="{{ 'menuBarDetails.showButtons' | translate }}"
        type="button"
        icon="icon-menu-bars"
        class="p-menu-bar-button"
        [attr.data-cy-toggle-state]="showMoreAction ? 'opened' : 'closed'"
        (mouseup)="showMoreActionEmitter.emit($event)"></button>
    }
  </div>

  @if (!isMobile && processesDisplayed?.length > 0) {
    <ul
      class="process-list"
      #processListContainer
      (mouseenter)="hoverProcessContainer = true"
      (mouseleave)="hideProcessList('container')">
      @for (process of processesDisplayed; track $index) {
        @if (tabId && tabId === process.tabId) {
          <li>
            <a href="#" class="open-icon" (click)="$event.preventDefault(); runProcess($index)">
              <span class="process-name">{{ process.componentRef.instance.itemData.data.label }}</span>
              <i class="fas fa-cog" aria-hidden="true"></i>
            </a>
          </li>
        }
      }
    </ul>
  }

  <div class="ui-center">
    @if (!isZoomEditView && !isZoomTarget && arrowsDisplayed) {
      <span
        (click)="changeGridElementEvent.emit(-1)"
        class="arrow back-arrow icon-back"
        iuKeybinding
        iuKeybinding-event-mode="mouseup"
        [iuKeybinding-shift]="false"
        [iuKeybinding-active]="activateKeybind && !isSplitView"
        [iuKeybinding-code]="'menu-bar-detail.back'"
        [iuKeybinding-focusTab]="activeTabID"></span>
    }
    <span
      tooltipPosition="top"
      showDelay="250"
      [life]="3000"
      [hideDelay]="250"
      pTooltip="{{ viewRecordChangeLogLabel }}"
      class="tabTitle"
      data-cy="show-changelog"
      (click)="viewRecordChangeLog.emit($event)">
      {{ viewRecordChangeLogLabel | slice: 0 : (!isMobile ? 28 : 20) }} </span
    ><span class="newSymbol">{{ status === 'sync' ? '' : ' *' }}</span>
    @if (!isZoomEditView && !isZoomTarget && arrowsDisplayed) {
      <span
        (click)="changeGridElementEvent.emit(1)"
        class="arrow next-arrow icon-next"
        iuKeybinding
        iuKeybinding-event-mode="mouseup"
        [iuKeybinding-shift]="false"
        [iuKeybinding-active]="activateKeybind && !isSplitView"
        [iuKeybinding-code]="'menu-bar-detail.next'"
        [iuKeybinding-focusTab]="activeTabID"></span>
    }
  </div>

  <div class="ui-right">
    @if (!isMobile) {
     <!-- <button
        pButton
        tooltipPosition="left"
        showDelay="250"
        [life]="3000"
        [hideDelay]="250"
        pTooltip="{{ 'menuBarDetails.email' | translate }}"
        type="button"
        class="p-menu-bar-button"
        icon="icon-mail"
        [disabled]="!mailBtnCondition"
        (mouseup)="emailEvent && $event.button === 0 ? emailEvent.emit($event) : ''"
        iuKeybinding
        iuKeybinding-event-mode="mouseup"
        [iuKeybinding-active]="activateKeybind && !isSplitView"
        iuKeybinding-code="menu-bar-detail.email"
        [iuKeybinding-focusTab]="activeTabID"
        data-cy="button-email"></button>
      <button
        pButton
        tooltipPosition="left"
        showDelay="250"
        [life]="3000"
        [hideDelay]="250"
        pTooltip="{{ 'menuBarDetails.note' | translate }}"
        type="button"
        class="p-menu-bar-button"
        icon="icon-notes"
        [disabled]="!noteBtnCondition"
        (mouseup)="noteEvent && $event.button === 0 ? noteEvent.emit($event) : ''"
        iuKeybinding
        iuKeybinding-event-mode="mouseup"
        [iuKeybinding-active]="activateKeybind && !isSplitView"
        iuKeybinding-code="menu-bar-detail.note"
        [iuKeybinding-focusTab]="activeTabID"
        data-cy="button-note"></button>
      <button
        pButton
        tooltipPosition="left"
        showDelay="250"
        [life]="3000"
        [hideDelay]="250"
        pTooltip="{{ 'menuBarDetails.joinFiles' | translate }}"
        type="button"
        class="p-menu-bar-button"
        icon="icon-attach3x"
        [disabled]="!joinFileBtnCondition"
        (mouseup)="joinFilesEvent && $event.button === 0 ? joinFilesEvent.emit($event) : ''"
        iuKeybinding
        iuKeybinding-event-mode="mouseup"
        [iuKeybinding-active]="activateKeybind && !isSplitView"
        iuKeybinding-code="menu-bar-detail.join"
        [iuKeybinding-focusTab]="activeTabID"
        data-cy="button-attachFile"
        style="overflow: visible">
        @if (nbUploadedFiles !== undefined && nbUploadedFiles > 0) {
          <span class="iupics-badge iupics-badge-count" style="top: 0; right: -2px">{{
            nbUploadedFiles > 9 ? '9+' : nbUploadedFiles
          }}</span>
        }
      </button>-->
      <button
        pButton
        tooltipPosition="left"
        showDelay="250"
        [life]="3000"
        [hideDelay]="250"
        pTooltip="{{ 'menuBarDetails.export' | translate }}"
        type="button"
        class="p-menu-bar-button"
        icon="icon-export"
        [disabled]="!exportBtnCondition"
        (mouseup)="exportDataEvent && $event.button === 0 ? exportDataEvent.emit($event) : ''"
        iuKeybinding
        iuKeybinding-event-mode="mouseup"
        [iuKeybinding-active]="activateKeybind && !isSplitView"
        iuKeybinding-code="menu-bar-detail.export"
        [iuKeybinding-focusTab]="activeTabID"
        data-cy="button-export"></button>
      <button
        pButton
        tooltipPosition="left"
        showDelay="250"
        [life]="3000"
        [hideDelay]="250"
        pTooltip="{{ 'menuBarDetails.print' | translate }}"
        type="button"
        class="p-menu-bar-button"
        icon="icon-print"
        [disabled]="!printBtnCondition"
        (mouseup)="printEvent && $event.button === 0 ? printEvent.emit($event) : ''"
        iuKeybinding
        iuKeybinding-event-mode="mouseup"
        [iuKeybinding-active]="activateKeybind && !isSplitView"
        iuKeybinding-code="menu-bar-detail.print"
        [iuKeybinding-focusTab]="activeTabID"
        data-cy="button-print"></button>
    }
    <button
      pButton
      type="button"
      icon="icon-close"
      class="p-menu-bar-button"
      (mouseup)="$event.button === 0 ? closeEvent.emit($event) : ''"
      [life]="3000"
      [hideDelay]="250"
      pTooltip="{{ 'menuBarDetails.close' | translate }}"
      iuKeybinding
      iuKeybinding-event-mode="mouseup"
      [iuKeybinding-active]="activateKeybind && !isSplitView"
      iuKeybinding-code="menu-bar-detail.close"
      [iuKeybinding-focusTab]="activeTabID"
      data-cy="button-closeBlade"></button>
  </div>
</p-toolbar>

@if (showMoreAction) {
  <div class="more-action">
    @if (isZoomTarget) {
      <button
        pButton
        type="button"
        class="p-menu-bar-button"
        icon="icon-table"
        (mouseup)="changeGridViewVisibility ? changeGridViewVisibility.emit($event) : ''"></button>
    }
    <button
      pButton
      class="p-menu-bar-button"
      type="button"
      icon="icon-add"
      [disabled]="!newBtnCondition"
      (mouseup)="newEvent ? newEvent.emit($event) : ''"
      data-cy="button-newRecord"></button>
    <button
      pButton
      type="button"
      class="p-menu-bar-button"
      icon="icon-save3x"
      [disabled]="!saveBtnCondition"
      (mouseup)="saveEvent ? saveEvent.emit($event) : ''"
      data-cy="button-saveRecord"></button>
    <button
      pButton
      type="button"
      class="p-menu-bar-button"
      icon="icon-refresh3x"
      [disabled]="!refreshBtnCondition"
      (mouseup)="refreshEvent ? refreshEvent.emit($event) : ''"
      data-cy="button-refresh"></button>
    <button
      pButton
      type="button"
      class="p-menu-bar-button"
      icon="icon-delete"
      [disabled]="!deleteBtnCondition"
      (mouseup)="deleteEvent ? deleteEvent.emit($event) : ''"
      data-cy="button-delete"></button>
    <button
      pButton
      type="button"
      class="p-menu-bar-button"
      icon="icon-copy"
      [disabled]="!copyBtnCondition"
      (mouseup)="copyEvent ? copyEvent.emit($event) : ''"
      data-cy="button-copy"></button>
    <button
      pButton
      type="button"
      class="p-menu-bar-button"
      icon="icon-mail"
      [disabled]="!mailBtnCondition"
      (mouseup)="emailEvent ? emailEvent.emit($event) : ''"
      data-cy="button-email"></button>
    <button
      pButton
      type="button"
      class="p-menu-bar-button notes-button"
      icon="icon-notes"
      [disabled]="!noteBtnCondition"
      (mouseup)="noteEvent && $event.button === 0 ? noteEvent.emit($event) : ''"
      data-cy="button-note"></button>
    <button
      pButton
      type="button"
      class="p-menu-bar-button"
      icon="icon-attach3x"
      [disabled]="!joinFileBtnCondition"
      (mouseup)="joinFilesEvent ? joinFilesEvent.emit($event) : ''"
      data-cy="button-attachFile">
      @if (nbUploadedFiles !== undefined && nbUploadedFiles > 0) {
        <span class="iupics-badge iupics-badge-count">{{ nbUploadedFiles > 9 ? '9+' : nbUploadedFiles }}</span>
      }
    </button>
    <button
      pButton
      type="button"
      class="p-menu-bar-button"
      icon="icon-export"
      [disabled]="!exportBtnCondition"
      (mouseup)="exportDataEvent ? exportDataEvent.emit($event) : ''"
      data-cy="button-export"></button>
    <button
      pButton
      type="button"
      class="p-menu-bar-button"
      icon="icon-print"
      [disabled]="!printBtnCondition"
      (mouseup)="printEvent ? printEvent.emit($event) : ''"
      data-cy="button-print"></button>
    <button
      pButton
      type="button"
      class="p-menu-bar-button"
      icon="icon-close"
      (mouseup)="closeEvent.emit($event)"></button>

    <button
      pButton
      type="button"
      class="p-menu-bar-button open-icon-button"
      icon="icon-next-ancre"
      [disabled]="processes.length == 0"
      data-cy="button-process"
      (click)="showProcessList()"
      (mouseenter)="showProcessList()"></button>
  </div>
}
