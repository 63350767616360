<div class="iu-specific-window ethias-container" [ngClass]="{ 'iu-specific-window-container': !isModal }">
    <p-progressBar
    *ngIf="!isModal && isLoading"
    [mode]="'indeterminate'"
    [value]="0"
    [showValue]="false"
    [styleClass]="'loading-progressbar'"
  ></p-progressBar>
  <p-scrollPanel [style]="{ width: '100%' }" [ngClass]="{ 'iu-modal': isModal }">
    <div class="ui-g-12">
      <div class="ui-g-12 ethias-top-item">
        <ng-template #filter></ng-template>
      </div>

      <div class="ui-g-12 ethias-first-table">
        <span class="specific-title">
          <b>{{ 'EthiasManagement.titleTableDeclarants' | translate }}</b>
        </span>
        <ng-template #tableDeclarants></ng-template>
      </div>

      <div class="ui-g-12 ethias-second-table">
        <span class="specific-title">
          <b>{{ 'EthiasManagement.titleTableNonDeclarants' | translate }}</b>
        </span>
        <ng-template #tableNonDeclarants></ng-template>
      </div>

      <div class="ui-g-12 ethias-action-container">
        <ng-template #actions></ng-template>
      </div>
    </div>
  </p-scrollPanel>
</div>
