import {
  Component,
  ComponentFactoryResolver,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { CustomDesignItemType } from '@iupics-components/models/custom-design';
import { Global } from '@iupics-manager/models/global-var';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  CompiereDataGridFilterType,
  DataStoreRequest
} from './../../../../compiere-ws/models/compiere-data-json';
import { LocationService } from './../../../../compiere-ws/services/compiere-location/location.service';
import { CompiereProcessService } from './../../../../compiere-ws/services/compiere-process/compiere-process.service';
import { SocketService } from './../../../../compiere-ws/services/socket/socket.service';
import { DataStoreService } from './../../../../iupics-manager/managers/data-store/data-store.service';
import { MessageManagerService } from './../../../../iupics-manager/managers/message/message-manager.service';
import { SecurityManagerService } from './../../../../iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from './../../../../iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryService } from './../../../../iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { DynamicComponent } from './../../../../iupics-manager/models/dynamic-component';
import { IupicsTypeEvent } from './../../../../iupics-manager/models/iupics-event';
import { OperatorFilterType } from './../../../models/universal-filter';
import { InfoDialogType } from './../info-dialog/info-dialog.component';
import SpecificWindowUiComponent from './../specific-window-ui/specific-window-ui.component';
import { NgClass } from '@angular/common';
import { ProgressBarModule } from 'primeng/progressbar';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { DynamicContainerDirective } from '@iupics-util/directives/dynamic-container.directive';
import ButtonUiComponent from '@iupics-components/standard/fields/button-ui/button-ui.component';
import ModalUiComponent from '@iupics-components/standard/layouts/modal-ui/modal-ui.component';

@Component({
  selector: 'iu-create-update-owner',
  templateUrl: './create-update-owner.component.html',
  styleUrls: ['./create-update-owner.component.scss'],
  standalone:true,
  imports: [
    NgClass,
    ProgressBarModule,
    ScrollPanelModule,
    DynamicContainerDirective,
    ButtonUiComponent,
    ModalUiComponent,
    // UniversalFilterUiComponent,
    // GridTabInfinityScrollUiComponent,
    TranslateModule,
  ],
})
export class CreateUpdateOwnerComponent extends SpecificWindowUiComponent
  implements OnInit {
  @ViewChild('top', { read: ViewContainerRef, static: true })
  vcrTop: ViewContainerRef;
  isChanged = false;
  isUpdate = false;
  additionalParams = {};

  constructor(
    private locationService: LocationService
  ) {
    super(
    );
    this.isModal = false;
    this.customDesignArray.push(
      {
        vcr: 'vcrTop',
        type: CustomDesignItemType.FIELD,
        columnName: 'Nom',
        cssClass: 'ui-g-12'
      },
      {
        vcr: 'vcrTop',
        type: CustomDesignItemType.FIELD,
        columnName: 'Nom2',
        cssClass: 'ui-g-12'
      },
      {
        vcr: 'vcrTop',
        type: CustomDesignItemType.FIELD,
        columnName: 'C_Location_ID',
        cssClass: 'ui-g-12'
      },
      {
        vcr: 'vcrTop',
        type: CustomDesignItemType.FIELD,
        columnName: 'Tel_tiers',
        cssClass: 'ui-g-12'
      },
      {
        vcr: 'vcrTop',
        type: CustomDesignItemType.FIELD,
        columnName: 'GSM_tiers',
        cssClass: 'ui-g-12'
      },
      {
        vcr: 'vcrTop',
        type: CustomDesignItemType.FIELD,
        columnName: 'Email_tiers',
        cssClass: 'ui-g-12'
      },
      {
        vcr: 'vcrTop',
        type: CustomDesignItemType.FIELD,
        columnName: 'valider',
        cssClass: 'ui-g-12'
      },
      {
        vcr: 'vcrTop',
        type: CustomDesignItemType.FIELD,
        columnName: 'cancel',
        cssClass: 'ui-g-12'
      }
    );
  }

  ngOnInit() {
    super.ngOnInit();
    this.additionalParams['AD_Org_ID'] = this.getCurrentContext()[
      'AD_Org_ID'
    ].id;
    const item: DynamicComponent = {
      container: this,
      DOMParentComponent: this,
      component: 'SpecificWindowUiComponent',
      cssClass: 'ui-g-12',
      isCssOnComponent: false,
      tabId: this.formId,
      gridPaginator: false
    };
    this.windowFactory.newEventHandler({
      type: IupicsTypeEvent.showSpecificWindow,
      item: item
    });

    if (this.dataStore.data['C_BPartner_ID']) {
      this.isUpdate = true;
      let request: DataStoreRequest = {
        windowId: null,
        parent_constraint: '',
        compiereRequest: {
          startRow: 0,
          endRow: 0,
          tableName: 'C_BPartner',
          filterModel: {
            C_BPartner_ID: {
              filterType: CompiereDataGridFilterType.SET,
              values: [this.dataStore.data['C_BPartner_ID'].id],
              operators: [OperatorFilterType.EQUALS]
            }
          }
        }
      };
      this.store.getDataGrid(request).subscribe(response => {
        this.dataStore.data['Nom'] = response.data[0]['NAME'];
        this.dataStore.data['Nom2'] = response.data[0]['NAME2'];
        this.dataStore.data['Email_tiers'] = response.data[0]['EMAIL_TIERS'];
        this.dataStore.data['Tel_tiers'] = response.data[0]['TEL_TIERS'];
        this.dataStore.data['GSM_tiers'] = response.data[0]['GSM_TIERS'];
        request = {
          windowId: null,
          parent_constraint: '',
          compiereRequest: {
            startRow: 0,
            endRow: 0,
            tableName: 'C_BPartner_Location',
            filterModel: {
              C_BPartner_ID: {
                filterType: CompiereDataGridFilterType.SET,
                values: [this.dataStore.data['C_BPartner_ID'].id],
                operators: [OperatorFilterType.EQUALS]
              }
            }
          }
        };

        this.store.getDataGrid(request).subscribe(response2 => {
          if (response2.data[0]) {
            this.locationService
              .getLocation(response2.data[0]['C_LOCATION_ID'])
              .subscribe(response => {
                const addressData = [
                  response['Address1'],
                  response['Address2'],
                  response['Address3'],
                  response['Address4'],
                  response['Postal'],
                  response['RegionName'],
                  response['City']
                ];
                const addressStr = addressData
                  .filter(
                    item => item !== undefined && item !== null && item !== ''
                  )
                  .join(',');

                this.dataStore.data['C_Location_ID'] = {
                  id: response['C_Location_ID'],
                  displayValue: addressStr
                };
                this.setDatacontainerValue(
                  'C_Location_ID',
                  this.dataStore.data['C_Location_ID']
                );
                this.setDataContainersValueWithChangedStore();
              });
          }
        });
      });
    }
  }

  notifyFromDataChange(item) {
    super.notifyFromDataChange(item);
    this.isChanged = true;
  }

  processvalider(item) {
    if (this.isChanged) {
      this.dataStore.data['valider'] = this.isUpdate
        ? this.dataStore.data['C_BPartner_ID'].id
        : null;
      this.executeProcess(item.processId, this.additionalParams);
    }
  }

  onEndProcess() {
    this.parentComponent.updateAll(false);
    this.parentComponent.updateModalDisplay({
      key: 'displayFormUI',
      value: false
    });
  }

  processcancel(item) {
    if (this.isChanged) {
      Global.infoDialog.message = {
        summary: this.translateService.instant(
          'infodialog.dialogs.checkBefore.close.title'
        ),
        detail: this.translateService.instant(
          'infodialog.dialogs.checkBefore.close.message'
        )
      };

      Global.infoDialog.dialogType = InfoDialogType.CONFIRM_YESNO;
      Global.infoDialog.showInfoDialog();
      const confirm = Global.infoDialog.confirm.subscribe((e: any) => {
        this.dataStore.data['valider'] = this.isUpdate
          ? this.dataStore.data['C_BPartner_ID'].id
          : null;

        this.fields.forEach(field => {
          if (field.data['columnName'] === 'valider') {
            this.executeProcess(field.processId, this.additionalParams);
            this.parentComponent.updateModalDisplay({
              key: 'displayFormUI',
              value: false
            });
            confirm.unsubscribe();
            cancel.unsubscribe();
          }
        });
      });
      const cancel = Global.infoDialog.cancel.subscribe(e => {
        // ANNULATION
        confirm.unsubscribe();
        cancel.unsubscribe();
        this.parentComponent.updateModalDisplay({
          key: 'displayFormUI',
          value: false
        });
      });
    } else {
      this.parentComponent.updateModalDisplay({
        key: 'displayFormUI',
        value: false
      });
    }
  }
}
