<div class="iu-specific-window iu-specific-window-container">
  <p-progressBar
          *ngIf="!isModal && isLoading"
          [mode]="'indeterminate'"
          [value]="0"
          [showValue]="false"
          [styleClass]="'loading-progressbar'"
  ></p-progressBar>
  <p-scrollPanel [style]="{ width: '100%' }">
    <ng-template #top></ng-template>
    <div class="p-col-12">
      <google-map
              [height]="'400px'"
              [width]="'100%'"
              [center]="{ lat: lat, lng: lng }"
              [zoom]="zoom"
              (mapClick)="mapClicked($event)"
      >
      </google-map>
    </div>
    <div class="p-col-12 row-fields">
      <div class="p-col-6"></div>
      <iu-button-ui
              class="iu-btn btn-cancel p-col-3 p-md-3 p-lg-3"
              (click)="
          $event.preventDefault();
          $event.stopPropagation();
          closeModalEmitter.emit()
        "
              label="{{ 'process.cancel' | translate }}"
              icon="fa fa-ban"
      >
      </iu-button-ui>
      <iu-button-ui
              class="iu-btn btn-save p-col-3 p-md-3 p-lg-3"
              (click)="
          save();
          $event.preventDefault();
          $event.stopPropagation();
          closeModalEmitter.emit()
        "
              label="{{ 'generic.save' | translate }}"
              icon="fas fa-save"
      >
      </iu-button-ui>
      <!-- <ng-template #bottom></ng-template> -->
    </div>
  </p-scrollPanel>
</div>
