<div class="iu-specific-window" [ngClass]="{ 'iu-specific-window-container': !isModal }">
    <p-progressBar
    *ngIf="!isModal && isLoading"
    [mode]="'indeterminate'"
    [value]="0"
    [showValue]="false"
    [styleClass]="'loading-progressbar'"
  ></p-progressBar>
  <p-scrollPanel [style]="{ width: '100%' }" [ngClass]="{ 'iu-modal': isModal }">
    <div class="ui-g-12 fields">
      <div class="top-container-CreateFromShipment">
        <div class="mass-complem-container">
          <div class="ui-g-12 ui-md-4 mass-complem-item"><ng-template #top></ng-template></div>
          <div class="ui-g-12 ui-md-8" style="padding-top: 20px">
            <p class="help">{{ 'massComplemInsurance.title' | translate }}</p>
          </div>
        </div>
        <div class="p-col-12">
          <ng-template #middle></ng-template>
        </div>
        <div class="p-col-12">
          <ng-template #bot></ng-template>
        </div>
      </div>
    </div>

    <div class="iu-specific-btn-container">
      <a
        *ngIf="isModal"
        class="iu-btn btn-cancel"
        (click)="$event.preventDefault(); $event.stopPropagation(); closeModalEmitter.emit()"
      >
        <i class="fa fa-ban"></i> {{ 'process.cancel' | translate }}
      </a>
    </div>
  </p-scrollPanel>
</div>
<!--
<ng-container *ngIf="displaySearch">
  <iu-modal-ui
    [title]="this.searchLinkedComponent.autoComplete.label"
    [hasCloseBtn]="true"
    (closeModalEmitter)="displaySearch = false"
  >
    <div class="autocomplete-universal-filter">
      <iu-universal-filter-ui
        [tabId]="-1"
        [columns]="searchLinkedComponent.autoComplete.data.searchColumns"
        [gridTab]="gridTab"
      ></iu-universal-filter-ui>
    </div>
    <iu-grid-tab-infinity-scroll-ui
      #gridTab
      (searchEmitter)="this.searchLinkedComponent.setSearchSelectItem($event); displaySearch = false"
      [data]="this.searchLinkedComponent.autoComplete.data"
      [isSearch]="true"
      [dataStored]="this.searchLinkedComponent.autoComplete.dataStored"
    ></iu-grid-tab-infinity-scroll-ui>
  </iu-modal-ui>
</ng-container>
-->
