<div class="iu-specific-window post-rejected-container" [ngClass]="{ 'iu-specific-window-container': !isModal }">
    <p-progressBar
    *ngIf="!isModal && isLoading"
    [mode]="'indeterminate'"
    [value]="0"
    [showValue]="false"
    [styleClass]="'loading-progressbar'"
  ></p-progressBar>
  <p-scrollPanel [style]="{ width: '100%' }" [ngClass]="{ 'iu-modal': isModal }">
    <div class="p-col-12">
      <div class="p-col-12">
        <span class="specific-title">
          <b>{{ 'postRejected.label' | translate }}</b>
        </span>
      </div>

      <div class="p-col-12 post-rejected-top-container">
        <ng-template #topLeft></ng-template>
        <ng-template #topRight></ng-template>
      </div>

      <div class="p-col-12">
        <span class="specific-title" style="font-size: 20px">
          <b>{{ 'postRejected.tableTitle' | translate }}</b>
        </span>
          <apiz-grid-headless
            #table
            class="apiz-grid-headless"
            [columnDefs]="tableColumnDefs"
            [columnFitContent]="true"
            [components]="frameworkComponents"
            [data]="tableRowData"
            [pagination]="true"
            [paginationPageCountOptions]="[10, 20, 30, 40, 50, 'AUTO']"
          />
        <span class="specific-title">
          <small>
            <b>{{ tableRowData.length }} {{ 'postRejected.tableLabel' | translate }}</b>
          </small>
        </span>
      </div>

      <div class="p-col-4 post-rejected-bottom-container">
        <ng-template #bottom class="ui-right"></ng-template>
      </div>
    </div>
  </p-scrollPanel>
</div>
