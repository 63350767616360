import { EmailModel } from '@compiere-ws/models/compiere-mail-json';
import { IupicsColumnInfo } from '@iupics-manager/models/iupics_column_info';
import { FilterOperator } from './universal-filter';

/**
 * Check is the object is like an IAutocomplete and cast it as IAutocomplete.
 * @param o Object to test and cast in IAutocomplete
 */
export function isAutocompleteLike(o: any): o is IAutocomplete {
  return typeof o === 'object' && o.hasOwnProperty('id') && o.hasOwnProperty('displayValue');
}

/**
 *
 * @param value
 */
export function getId(value: any): any {
  if (value.hasOwnProperty('id')) return value.id;
  else return value;
}

export type IAutocomplete = {
  id: any;
  displayValue: string;
};

export type EmailAutocomplete = IAutocomplete & {
  id: number;
  model?: EmailModel;
};

export type ColumnFilterAutocomplete = IAutocomplete & {
  columnInfo?: IupicsColumnInfo;
};

export type OperatorFilterAutocomplete = IAutocomplete & {
  id: number;
  operator?: FilterOperator;
};

export type PrintFormatAutocomplete = IAutocomplete & {
  id: number;
  fileFormats?: IAutocomplete[];
};

export class CountryAutocomplete implements IAutocomplete {
  id: number;
  // #START CUSTO-GCB #170302
  value?: string;
  // #END CUSTO-GCB #170302
  displayValue: string;
}
