import { NgStyle, NgTemplateOutlet } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation, inject } from '@angular/core';
import { ViewType } from '@iupics-components/models/view-type.enum';
import { FavoriteSelectorComponent } from '@iupics-components/standard/grid/favorite-selector/favorite-selector.component';
import { Global } from '@iupics-manager/models/global-var';
import { ContentProjectionDirective, ToolbarComponent } from '@iupics/apiz-grid';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { isNil } from 'lodash';
import { ButtonModule } from 'primeng/button';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { KeybindingDirective } from '../../../directives/keybinding.directive';
import UniversalFilterUiComponent from '../universal-filter-ui/universal-filter-ui.component';

import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';

@Component({
  selector: 'iu-menu-bar-ui',
  templateUrl: './menu-bar-ui.component.html',
  styleUrls: ['./menu-bar-ui.component.css'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    ToolbarModule,
    NgStyle,
    ButtonModule,
    KeybindingDirective,
    TooltipModule,
    UniversalFilterUiComponent,
    TranslateModule,
    NgTemplateOutlet,
    //#region Apiz-grid
    ToolbarComponent,
    ContentProjectionDirective,
    FavoriteSelectorComponent,
    //#endregion
  ],
})
export default class MenuBarUiComponent implements OnInit {
  #translate = inject(TranslateService);
  connectorService = inject(SecurityManagerService);

  @Input() isFromForm = false;
  @Input() activeTabID: string;
  @Input() gridView: any;
  /**
   * Sers à cacher le menu-bar detail pour les forms
   */
  @Input() hasUniversalFilter = true;
  @Input() isDeleteable = true;
  @Input() isInsertRecord = true;
  @Input() isReadOnly = false;
  @Input() isSplitView = false;
  @Input() isTabTopLevel = true;
  @Input() tabId: number;
  @Input() visibleButton = true;
  @Input() visibleTreeButton: boolean;

  @Output() changeViewEvent = new EventEmitter();
  @Output() filterChange = new EventEmitter<boolean>();
  @Output() newEmitter = new EventEmitter();
  @Output() openExportDataModalEmitter = new EventEmitter();
  @Output() refreshEmitter = new EventEmitter();
  @Output() removeEmitter = new EventEmitter<MouseEvent>();
  newBtnCondition = false;
  visibleButtonMultiSelection = false;
  tooltipLabels: any;
  isMobile = Global.isMobile();
  showMoreAction = false;

  get isRecordActive(): boolean {
    let response = true;
    if (
      this.gridView?.DOMParentComponent?.dataStored?.data?.hasOwnProperty('IsActive') &&
      !isNil(this.gridView.DOMParentComponent.dataStored.data.IsActive)
    ) {
      response = this.gridView.DOMParentComponent.dataStored.data.IsActive === 'Y';
    }

    return response;
  }

  ngOnInit() {
    // #START CUSTO-GCB #170302
    if (this.tabId == Global.camp_ete_first_tab_id) {
      const campEteAuthorizedRolesTxt = Global.ad_message_text.get('GUIDES_CAMP_ETE_CAN_ADD_ROLES');
      const campEteAuthorizedRoles = campEteAuthorizedRolesTxt ? campEteAuthorizedRolesTxt.split(',') : [];
      this.isInsertRecord = campEteAuthorizedRoles.includes(
          this.connectorService.getIupicsUserContext()['#AD_Role_ID'].toString()
      );
    } else {
      this.isInsertRecord = this.gridView.data.isInsertRecord;
    }
    // #END CUSTO-GCB #170302
    this.tooltipLabels = {
      add: this.#translate.instant('menuBarUi.add'),
      delete: this.#translate.instant('menuBarUi.delete'),
      refresh: this.#translate.instant('menuBarUi.refresh'),
      export: this.#translate.instant('menuBarUi.export'),
      gridView: this.#translate.instant('menuBarUi.gridView'),
      kanbanView: this.#translate.instant('menuBarUi.kanbanView'),
      calendarView: this.#translate.instant('menuBarUi.calendarView'),
      chartView: this.#translate.instant('menuBarUi.chartView'),
      treeView: this.#translate.instant('menuBarUi.treeView'),
      filter: this.#translate.instant('menuBarUi.filter'),
      search: this.#translate.instant('menuBarUi.search'),
    };
    this.newBtnCondition = !this.isReadOnly && this.isInsertRecord && this.isRecordActive;
  }
  onShowMoreAction(event) {
    event.stopPropagation();
    this.showMoreAction = !this.showMoreAction;
  }

  setVisibleButton(visible: boolean) {
    this.visibleButton = visible;
  }

  setVisibleButtonMultiSelection(visible: boolean) {
    this.visibleButtonMultiSelection = visible;
  }

  switchToGridView() {
    this.changeViewEvent.emit(ViewType.GRID);
  }

  switchToKanbanView() {
    this.changeViewEvent.emit(ViewType.KANBAN);
  }

  switchToCalendarView() {
    this.changeViewEvent.emit(ViewType.CALENDAR);
  }

  switchToChartView() {
    this.changeViewEvent.emit(ViewType.CHART);
  }

  switchToTreeView() {
    this.changeViewEvent.emit(ViewType.TREE);
  }

  updateButtonLists() {
    this.newBtnCondition = !this.isReadOnly && this.isInsertRecord && this.isRecordActive;
  }
}
