import { Component, forwardRef } from '@angular/core';
import { DataStore } from '@compiere-ws/models/compiere-data-json';
import { CellEditorComp, CellEditorParams } from '@iupics/apiz-grid';
import AutocompleteUiComponent from '../../../standard/fields/autocomplete-ui/autocomplete-ui.component';

@Component({
  selector: 'iu-autocomplete-editor',
  template: `
    <iu-autocomplete-ui
      label="{{ label }}"
      [cssClass]="'p-col-12 autocompleteEditor'"
      [isLabelDisplay]="false"
      [limitSuggestions]="25"
      (autoCompleteEmitter)="onSelect($event)"
      (checkGridEditorEmitter)="onSearch($event)"
      (blurEmitter)="closePanel($event)"
      [data]="templates"
      [fieldValue]="this.fieldValue"
      [isEditorView]="true"
      [dataStoredEditor]="dataStored" />
  `,
  standalone: true,
  imports: [forwardRef(() => AutocompleteUiComponent)],
})
export default class AutocompleteEditorComponent implements CellEditorComp {
  params: CellEditorParams;
  label: string;
  templates: any;
  fieldValue = null;
  dataStored: DataStore;

  gridInit(params: CellEditorParams) {
    this.params = params;
    this.label = this.params.label || null;
    this.templates = this.params.templates;
    this.dataStored = new DataStore();
    this.dataStored.data = this.params.data;
    if (params.value) {
      if (params.value.id !== null && params.value.id !== undefined) {
        this.fieldValue = {
          id: params.value.id,
          displayValue: params.value.displayValue,
        };
      } else {
        this.fieldValue = {
          id: params.value,
          displayValue: params.valueFormatted,
        };
      }
    }
  }

  refresh(params: CellEditorParams): boolean {
    if (params.value) {
      if (params.value.id !== null && params.value.id !== undefined) {
        this.fieldValue = {
          id: params.value.id,
          displayValue: params.value.displayValue,
        };
      } else {
        this.fieldValue = {
          id: params.value,
          displayValue: params.data.valueFormatted,
        };
      }
    }
    return true;
  }

  getValue() {
    return this.fieldValue;
  }

  onSelect($event) {
    // #START CUSTO-GCB #170302
    this.params.row.setData($event);
    // #END CUSTO-GCB #170302

    if (!this.params.row.isSelected()) {
      this.params.row.setSelected(true); // select row
    }

    if (this.params.onSelect instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.row.getRowData(),
        columnName: this.params.colDef.field,
        // ...something
      };
      this.params.onSelect({ ...this.params, ...params });
    }
  }

  onSearch(event) {
    this.params.onSearch(event);
  }

  closePanel(event) {
    this.params.closePanel();
  }
}
