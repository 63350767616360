import { Component, ComponentFactoryResolver, OnInit, forwardRef } from '@angular/core';
import { Global } from '@iupics-manager/models/global-var';
import { InfoDialogType } from '../info-dialog/info-dialog.component';
import SpecificWindowUiComponent from './../specific-window-ui/specific-window-ui.component';

import { NgClass, NgIf, NgStyle } from '@angular/common';
import { ProgressBarModule } from 'primeng/progressbar';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SidebarModule } from 'primeng/sidebar';
import { DynamicContainerDirective } from '@iupics-util/directives/dynamic-container.directive';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import ButtonUiComponent from '@iupics-components/standard/fields/button-ui/button-ui.component';
import ModalUiComponent from '@iupics-components/standard/layouts/modal-ui/modal-ui.component';
@Component({
  selector: 'iu-inscription-post-evenement',
  templateUrl: './inscription-post-evenement.component.html',
  styleUrls: ['./inscription-post-evenement.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    ProgressBarModule,
    ScrollPanelModule,
    SidebarModule,
    DynamicContainerDirective,
    TranslateModule,
    forwardRef(() => ButtonUiComponent),
    forwardRef(() => ModalUiComponent),
  ],
})
export class InscriptionPostEvenementComponent extends SpecificWindowUiComponent implements OnInit {
  constructor(
  ) {
    super();
  }
  ngOnInit() {
    super.ngOnInit();
  }
  processregisterSelected(item) {
    Global.infoDialog.message = {
      summary: 'Confirmation',

      detail: 'Etes-vous sur de vouloir faire cette action?'
    };

    Global.infoDialog.dialogType = InfoDialogType.CONFIRM_YESNO;

    Global.infoDialog.showInfoDialog();

    const confirm = Global.infoDialog.confirm.subscribe((e: any) => {
      // FAIS TON TRAITEMENT ICI
      const button = this.getDatacontainer('registerSelected');

      this.executeProcess(item.processId);
      //#128646
      if (button) {
        button.isReadOnly = true;
      }
      //#128646
      confirm.unsubscribe();

      cancel.unsubscribe();
    });

    const cancel = Global.infoDialog.cancel.subscribe(e => {
      // ANNULATION

      confirm.unsubscribe();

      cancel.unsubscribe();
    });
  }

  onEndProcess() {
    const button = this.getDatacontainer('registerSelected');
    if (button) {
      button.isReadOnly = false;
    }
    this.refreshGrids(this.dataStore, false);
    this.clearAllGridsSelection();
  }

  notifyFromDataChange(item?: any) {
    if (item.data['columnName'] === 'AD_Org_ID') {
      let champ = this.dataContainers.find(datacontainer => datacontainer.data.columnName === 'Z_Warehouse_ID');
      champ.dataChange(null);

      champ = this.dataContainers.find(datacontainer => datacontainer.data.columnName === 'Z_Project_ID');
      champ.dataChange(null);
    }
    super.notifyFromDataChange(item);
  }
}
