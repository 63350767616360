import { Component,  OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { CustomDesignItemType } from '@iupics-components/models/custom-design';
import GridViewUiComponent from '@iupics-components/standard/grid/grid-view-ui/grid-view-ui.component';
import { DynamicComponent } from '@iupics-manager/models/dynamic-component';
import { Global } from '@iupics-manager/models/global-var';
import { IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import SpecificWindowUiComponent from '../specific-window-ui/specific-window-ui.component';
import { TooltipModule } from 'primeng/tooltip';
import { NgClass, NgIf } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import PrimeOverlayComponent from '@iupics-components/overrided/prime-overlay/prime-overlay.component';
import ValuePreferencePanelComponent
  from '@iupics-components/standard/value-preference-panel/value-preference-panel.component';
import { TextLimitPipe } from '@iupics-util/pipes/text-limit/text-limit.pipe';
import ModalUiComponent from '@iupics-components/standard/layouts/modal-ui/modal-ui.component';
import ButtonEditorComponent from '@iupics-components/specific/window/editor/button-editor.component';
import AutocompleteEditorComponent from '@iupics-components/specific/window/editor/autocomplete-editor.component';
import { TranslateModule } from '@ngx-translate/core';
import { ProgressBarModule } from 'primeng/progressbar';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { CompiereDataGridSortModelType } from '@compiere-ws/models/compiere-data-json';
import {SortModel} from "@iupics/apiz-grid";
@Component({
  selector: 'iu-gps-project',
  templateUrl: './gps-project.component.html',
  styleUrls: ['./gps-project.component.scss'],
  standalone: true,
  imports: [
    TooltipModule,
    NgIf,
    NgClass,
    FormsModule,
    PrimeOverlayComponent,
    ValuePreferencePanelComponent,
    TextLimitPipe,
    ModalUiComponent,
    NgIf,
    ReactiveFormsModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    ProgressBarModule,
    ScrollPanelModule
  ],
})
export class GpsProjectComponent extends SpecificWindowUiComponent implements OnInit {
  @ViewChild('filtersDistance', { read: ViewContainerRef, static: true })
  vcrFiltersDistance: ViewContainerRef;
  @ViewChild('filtersDistance2', { read: ViewContainerRef, static: true })
  vcrFiltersDistance2: ViewContainerRef;
  @ViewChild('filters', { read: ViewContainerRef, static: true })
  vcrFilters: ViewContainerRef;
  @ViewChild('tableParent', { read: ViewContainerRef, static: true })
  vcrTableParent: ViewContainerRef;
  @ViewChild('actions', { read: ViewContainerRef, static: true })
  vcrActions: ViewContainerRef;

  frameworkComponents: any;
  currentFilters: string[];
  tableNames: string[];
  geoDistanceSortModel: SortModel[] = [];

  constructor() {
    super();
    this.frameworkComponents = {
      buttonEditor: ButtonEditorComponent,
      autocompleteEditor: AutocompleteEditorComponent,
    };
    this.tableNames = ['Rechercher Camp Table Gps Camp'];
  }

  ngOnInit() {
    this.currentFilters = [];
    this.uiCreator.getSpecificWindow(this.formId).subscribe((specificWindow) => {
      specificWindow.items.forEach((item) => {
        switch (item.data.columnName) {
          case 'Geo_Address':
            this.customDesignArray.push({
              vcr: 'vcrFiltersDistance2',
              type: CustomDesignItemType.FIELD,
              columnName: 'Geo_Address',
              cssClass: Global.isMobileWidth() ? 'p-col-4' : 'ui-lg-4 ui-md-4 p-col-4',
            });
            break;

          case 'Geo_MaxDistance':
            this.customDesignArray.push({
              vcr: 'vcrFilters',
              type: CustomDesignItemType.FIELD,
              columnName: 'Geo_MaxDistance',
              cssClass: Global.isMobileWidth() ? 'p-col-4' : 'ui-lg-4 ui-md-4 p-col-4',
            });
            break;

          case 'Geo_Location_Lat':
            this.customDesignArray.push({
              vcr: 'vcrFiltersDistance',
              type: CustomDesignItemType.FIELD,
              columnName: 'Geo_Location_Lat',
              cssClass: Global.isMobileWidth() ? 'p-col-4' : 'ui-lg-4 ui-md-4 p-col-4',
            });
            break;

          case 'Geo_Location_Lng':
            this.customDesignArray.push({
              vcr: 'vcrFiltersDistance',
              type: CustomDesignItemType.FIELD,
              columnName: 'Geo_Location_Lng',
              cssClass: Global.isMobileWidth() ? 'p-col-12' : 'ui-lg-4 ui-md-4 p-col-4',
            });
            break;
          default:
            if (item.component === 'GridViewUiComponent') {
              this.customDesignArray.push({
                vcr: 'vcrTableParent',
                type: CustomDesignItemType.GRID,
                tableName: item.name,
                cssClass: 'p-col-12',
                shouldSelectFirst: false,
                frameworkComponents: this.frameworkComponents,
                isFitResize: true,
                rowSelection: 'single',
                suppressRowClickSelection: false,
              });
            }
            break;
        }
      });
      //this.setExcludedFiltersToGrid(this.tableNames[0], ['Geo_MaxDistance', 'Geo_Location_Lat', 'Geo_Location_Lng']);
      const item: DynamicComponent = {
        container: this,
        DOMParentComponent: this,
        component: 'SpecificWindowUiComponent',
        cssClass: 'p-col-12',
        isCssOnComponent: false,
        tabId: this.formId,
        gridPaginator: false,
      };

      this.windowFactory.newEventHandler({
        type: IupicsTypeEvent.showSpecificWindow,
        item: item,
      });
    });
  }

  notifyFromDataChange(item?: any) {
    if (
      (item.data['columnName'] === 'Geo_Location_Lat' || item.data['columnName'] === 'Geo_Location_Lng') &&
      this.dataStore.data['Geo_Location_Lat'] &&
      this.dataStore.data['Geo_Location_Lng']
    ) {
      this.setExcludedFiltersToGrid(this.tableNames[0], ['Geo_Address']);
      this.currentFilters = ['Geo_Location_Lat', 'Geo_Location_Lng', 'Geo_MaxDistance'];
      this.resetLoc();
    } else {
      this.setExcludedFiltersToGrid(this.tableNames[0], []);
      if (
        item.data['columnName'] === 'Geo_Address' ||
        (item.data['columnName'] === 'Geo_MaxDistance' &&
          this.dataStore.data['Geo_Address'] != null &&
          this.dataStore.data['Geo_Address'] !== '')
      ) {
        if (this.dataStore.data['Geo_Address'] != null) {
          const geocode = new google.maps.Geocoder();
          geocode.geocode({ address: this.dataStore.data['Geo_Address'] }, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
              this.dataStore.data['Geo_Location_Lat'] = results[0].geometry.location.lat();
              this.dataStore.data['Geo_Location_Lng'] = results[0].geometry.location.lng();
              if (this.dataStore.dataChange) {
                const dataModified = {
                  Geo_Location_Lat: results[0].geometry.location.lat(),
                  Geo_Location_Lng: results[0].geometry.location.lng(),
                };
                this.dataStore.dataChange.emit({
                  dataModified: dataModified,
                  bypassValidation: false,
                  isFromCallout: true,
                });
              }
              this.refreshTable();
            } else {
              this.resetCoord();
            }
          });
        } else {
          this.resetCoord();
        }
      } else if (item.data['columnName'] === 'Geo_MaxDistance') {
        this.currentFilters = ['Geo_Location_Lat', 'Geo_Location_Lng', 'Geo_MaxDistance'];
        this.refreshTable();
        this.clearAllGridsSelection();
      }
    }
  }

  resetCoord() {
    this.dataStore.data['Geo_Location_Lat'] = 0;
    this.dataStore.data['Geo_Location_Lng'] = 0;
    if (this.dataStore.dataChange) {
      const dataModified = {
        Geo_Location_Lat: 0,
        Geo_Location_Lng: 0,
      };
      this.dataStore.dataChange.emit({
        dataModified: dataModified,
        bypassValidation: false,
        isFromCallout: true,
      });
    }
    this.clearAllGridsSelection();
    this.refreshGrids(this.dataStore, true);
  }
  resetLoc() {
    this.dataStore.data['Geo_Address'] = null;
    if (this.dataStore.dataChange) {
      const dataModified = {
        Geo_Address: null,
      };
      this.dataStore.dataChange.emit({
        dataModified: dataModified,
        bypassValidation: false,
        isFromCallout: true,
      });
    }
    this.clearAllGridsSelection();
    this.refreshGrids(this.dataStore, false);
  }
  notifyFromRowSelected(rowSelected: any) {
    if (rowSelected.length > 0) {
      Global.workspace.openTargetSearch({
        cat: { id: 130 },
        // #START CUSTO-GCB #170302
        source: { id: 'C_Project_ID,' + rowSelected[0]['C_Project_ID']["id"] },
        // #END CUSTO-GCB #170302
      });
    }
  }

  refreshTable() {
    const tableName = this.tableNames[0];
    const showTable = this.gridViews.find((grid) => (<GridViewUiComponent>grid).data['tableName'] === tableName);
    if (showTable) {
      /*const filter = showTable.filter || { filterModel: {}, sortModel: [] };
      if (filter.sortModel && !filter.sortModel.find((sm) => sm.colId === 'Geo_Distance')) {
        filter.sortModel.push({
          colId: 'Geo_Distance',
          sort: CompiereDataGridSortModelType.ASC
        });
        showTable.updateFilterEmitter.emit(filter);
        TODO::#170302 : filter doesnt exist
      }*/
      if (showTable.data.columnName === 'Rechercher Camp Table Gps Camp') {
        this.geoDistanceSortModel = [
          {
            colId: 'Geo_Distance',
            sort: CompiereDataGridSortModelType.ASC,
          },
        ];

        this.applyFilter(showTable, {
          sortModel: this.geoDistanceSortModel,
        });
      }

      this.refreshGrids(this.dataStore, false, null, showTable.tabId);
    }
  }

  reset() {
    this.dataStore.data['Geo_Address'] = '';
    this.dataStore.data['Geo_Location_Lat'] = 0;
    this.dataStore.data['Geo_Location_Lng'] = 0;
    this.dataStore.data['Geo_MaxDistance'] = 50;
    this.dataContainers.forEach((datacontainer) => {
      datacontainer.setNewData(this.dataStore);
    });
    this.clearAllGridsSelection();
    this.refreshGrids(this.dataStore, true);
  }
}
