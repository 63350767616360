import { NgClass, NgStyle } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnInit,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import { DataStore } from '@compiere-ws/models/compiere-data-json';
import { WindowFactoryUtils } from '@iupics-manager/managers/ui-creator/window-factory/window-factory-utils';
import { AbstractDataContainer } from '@iupics-manager/models/abstract-datacontainer';
import { IupicsEvent } from '@iupics-manager/models/iupics-event';
import { AccordionModule } from 'primeng/accordion';
import { timer } from 'rxjs';
import { DynamicContainerDirective } from '../../../../iupics-util/directives/dynamic-container.directive';
import { LogicEvaluator } from '@iupics-util/tools/logic-evaluator';

@Component({
  selector: 'iu-accordion-ui',
  templateUrl: './accordion-ui.component.html',
  styleUrls: ['./accordion-ui.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [AccordionModule, NgStyle, NgClass, DynamicContainerDirective],
})
export default class AccordionUiComponent extends AbstractDataContainer implements OnInit, AfterViewInit {
  #cd = inject(ChangeDetectorRef);

  @Input()
  selected: boolean;

  isMandatory: boolean;

  @HostBinding('class.p-col-nopad') hasNoPad = false;

  ngOnInit() {
    // #START CUSTO-GCB #170302
    this.isAccordion = true;
    super.ngOnInit();
    this.checkExpanded(this.dataStored);
    // #END CUSTO-GCB #170302
    this.cssGrid = 'p-col-12';
    if (this.data) {
      if (!this.data.isCollapsedDefault) {
        this.selected = true;
      } else {
        this.selected = this.data.isSelected;
      }
    }
    this.isAccordion = true;
  }

  onChildUpdate(event: IupicsEvent) {}

  onSiblingUpdate(event: IupicsEvent) {}

  onRemoveComponent(event: IupicsEvent) {}

  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.subscriptions.push(
      timer(0).subscribe(() => {
        for (const child of this.children) {
          WindowFactoryUtils.addContainerComponent({
            parent: this,
            item: child,
            isCssOnComponent: child.container ? child.isCssOnComponent : false,
          });
        }
        this.#cd.detectChanges();
      })
    );
  }

  setMandatory() {
    // check if mandatory children are OK
    let found = false;
    for (let i = 0; i < this.DOMChildrenComponent.length && !found; i++) {
      for (let j = 0; j < this.DOMChildrenComponent[i].DOMChildrenComponent.length && !found; j++) {
        const field = this.DOMChildrenComponent[i].DOMChildrenComponent[j];
        // si autocomplete
        if (
          !found &&
          (<any>field).isAutocompleteField === true &&
          field.data.isMandatory === true &&
          (<any>field).displayCss !== 'none' &&
          ((<any>field).fieldValue === undefined ||
            (<any>field).fieldValue === null ||
            !((<any>field).fieldValue instanceof Object))
        ) {
          found = true;
        }
        // si c'est autre qu'autocomplete
        if (
          !found &&
          field.data.isMandatory === true &&
          (<any>field).displayCss !== 'none' &&
          ((<any>field).fieldValue === undefined || (<any>field).fieldValue === '')
        ) {
          found = true;
        }
      }
    }
    this.isMandatory = found;
  }

  updateDisplay(dataStored: DataStore) {
    super.updateDisplay(dataStored);
    if (this.displayCss !== 'none') {
      this.displayCss = 'none';
      if (this.DOMChildrenComponent && this.DOMChildrenComponent.length > 0) {
        // pour toutes les row
        for (let i = 0; i < this.DOMChildrenComponent.length && this.displayCss === 'none'; i++) {
          const rowUI = this.DOMChildrenComponent[i];
          if (rowUI.DOMChildrenComponent && rowUI.DOMChildrenComponent.length > 0) {
            // pour tous les input de la row
            for (let j = 0; j < rowUI.DOMChildrenComponent.length && this.displayCss === 'none'; j++) {
              const input = rowUI.DOMChildrenComponent[j] as AbstractDataContainer;
              input.isFieldDisplay(dataStored).subscribe((displayed) => {
                if (displayed) {
                  this.displayCss = 'inline';
                }
              });
            }
          }
          // #START CUSTO-GCB #170302
          this.checkExpanded(dataStored);
          // #END CUSTO-GCB #170302
        }
      }
    }
    this.hasNoPad = this.displayCss === 'none';
  }
  // #START CUSTO-GCB #170302
  checkExpanded(dataStored: DataStore) {
    if (this.data) {
      if (this.data.isCollapsedDefault === false) {
        this.selected = true;
      } else {
        this.selected = this.data.isSelected;
      }
    }
    this.selected =
        this.data && this.data.isCollapsedDefault === false
            ? true
            : this.data && this.data.isExpandedLogic !== undefined && this.data.isExpandedLogic !== null
                ? LogicEvaluator.evaluateLogic(this.getCurrentContext(dataStored, false), this.data.isExpandedLogic)
                : false;
  }
  // #END CUSTO-GCB #170302
}
